import React, { useState, useEffect, Fragment, useLayoutEffect } from "react";
import {
  Select,
  Table,
  Slider,
  Checkbox,
  Spin,
  Pagination,
  Modal,
  Button,
  Empty,
  Input,
  Popover,
  Tooltip
} from "antd";
import colorPanelService from "../../../services/color-panel.service";
import styled from "styled-components";
import Info_icon from "../../../assets/images/info_icon.png";
import UnionIcon from "../../../assets/images/icons/Union_5.svg";
import Video_icon from "../../../assets/images/icons/video_icon.svg";
import Close from "../../../assets/images/close.png";
import Eye_icon from "../../../assets/images/eye__icon.svg";
import Rectangle from "../../../assets/images/Rectangle.png";
import {
  handleComaparePageIds,
  handleCompareKey,
  handleViewDetailsPage,
  handleSetSaveFilterLabTab,
  handleSetSaveEveryTimeLabTab,
} from "../../../actions/selectYourDiamond/selectYourDiamond";
import { useDispatch, useSelector } from "react-redux";
import SelectYourDiamondService from "../../../services/select-your-diamond.service";
import { handleAddDiamondViewEdit } from "../../../actions/header/headerActions";
import NotificationManager from "react-notifications/lib/NotificationManager";
import {
  handleViewCompletethisMounting,
  handleHideCompletethisMounting,
  handleHideCompleteEarningYourStudPage,
} from "../../../actions/chooseYourEarning/chooseYourEarningActions";
import NoPreview from "../../../assets/images/no_pre.png";
import { useNavigate, useLocation } from "react-router-dom";
import { handleSetPage } from "../../../actions/PageNavigations/PageNavigations";

const initialCertInfoData = {
  name: "",
  email: "",
  phoneNumber: "",
  comments: "",
};

const initialCertInfoDataVal = {
  nameVal: "",
  emailVal: "",
  phoneNumberVal: "",
  commentsVal: "",
};

const initialDiamondFormData = {
  firstName: "",
  lastName: "",
  phoneNumber: "",
  emailAddress: "",
  comments: "",
};
const initialDiamondFormDataVal = {
  firstNameVal: "",
  lastNameVal: "",
  phoneNumberVal: "",
  emailAddressVal: "",
  commentsVal: "",
};

const initialReqModalDataForApiRes = {
  show: false,
  success: true,
  msg: "",
};

const LabGrown = () => {
  const { Search } = Input;
  const search = useLocation().search;
  const navigate = useNavigate();
  const CompareIds = useSelector(
    (state) => state.selectYourDiamondReducer.compareIds
  );
  const paramdealerId = new URLSearchParams(search).get("DealerLink");
  const resetVal = useSelector((state) => state.selectYourDiamondReducer);
  const settingDetails = useSelector((state) => state.chooseYourSettingReducer);
  const loginDetails = useSelector((state) => state.loginDetailsReducer);
  const dispatch = useDispatch();
  const [productChange, setProductChange] = useState({});

  const [viewItem, setViewItem] = useState("list");
  const [diamondInfoDetails, setDiamondInfoDetails] = useState({});
  const [priceValue, setPriceValue] = useState([0, 1000000]);
  const [caratSlideValue, setCaratSlideValue] = useState([0, 22]);
  const [loading, setLoading] = useState(false);
  const [filterloading, setFilterLoading] = useState(false);
  const [reqDiamondloading, setReqDiamondLoading] = useState(false);
  const [labDiamondList, setLabDiamondList] = useState([]);
  const [isVideo, setIsVideo] = useState(false);
  const [certInfoData, setcertInfoData] = useState(initialCertInfoData);
  const [requestCertModal, setRequestMoreCertModal] = useState(false);
  const [certInfoValidation, setcertInfoDataValidation] = useState(
    initialCertInfoDataVal
  );

  const [videoImageModal, setVideoImageModal] = useState(false);
  const [selectedRowDetails, setSelectedRowDetails] = useState({});
  const [searchSku, setSearchSku] = useState("");
  const [viewCartButton, setViewCartButton] = useState(false);
  const [callForPrice, setCallForPrice] = useState(false);
  const [state, setState] = useState({
    productChange: {},
    PriceMin: 0,
    PriceMax: 1000000,
    CaratMin: 0,
    CaratMax: 22,
    Shape: [],
    Cut: [],
    Color: [],
    Clarity: [],
    Polish: [],
    Fluorescence: [],
    Symmetry: [],
    TableMin: 0,
    TableMax: 100,
    DepthMin: 0,
    DepthMax: 100,
    Certificates: [],
  });
  const [showAdvanced, setShowAdvanced] = useState(false);
  const [currencySymbol, setCurrencySymbol] = useState("");
  const [selectedCertificates, setSelectedCertificates] = useState(["all"]);
  const [viewCartButtonUrl, setViewCartButtonUrl] = useState("");
  const [requestCertModalLoading, setRequestMoreCertModalLoading] =
    useState(false);
  const [diamondFormData, setDiamondFormData] = useState(
    initialDiamondFormData
  );

  const [validations, setValidations] = useState(initialDiamondFormDataVal);
  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [TotalRowCount, setTotalRowCount] = useState(0);
  const [dealerId, setDealerId] = useState();
  const [rowDetails, setRowDetails] = useState({});
  const [filterVals, setFilterVals] = useState({
    Shape: [],
    Cut: [],
    Color: [],
    Clarity: [],
    Polish: [],
    Fluorescence: [],
    Symmetry: [],
    TableMin: 0,
    TableMax: 100,
    DepthMin: 0,
    DepthMax: 100,
    PriceMin: 0,
    PriceMax: 1000000,
    CaratMin: 0,
    CaratMax: 22,
    Certificates: [],
  });

  const [sortColumnName, setSortColumnName] = useState("");
  const [sortType, setSortType] = useState("asc");
  const [count, setCount] = useState(0);
  const [isAdvance, setIsAdvance] = useState(true);
  const [showAllContent, setShowAllContent] = useState(true);
  const [noDiamondToCompare, setNoDiamondToCompare] = useState(false);
  const [reqModalDataForApiRes, setReqModalDataForApiRes] = useState(
    initialReqModalDataForApiRes
  );

  useEffect(() => {
    if (paramdealerId) {
      let filters = filterVals;
      if (resetVal) {
        if (settingDetails) {
          if (settingDetails.selectedRow) {
            if (settingDetails.selectedRow.availableShapes) {
              let availableShape = [];
              let shapes = JSON.parse(
                settingDetails.selectedRow.availableShapes
              );
              filters["Shape"] = availableShape;
              setFilterVals(filters);
            }
          }
        }
        dispatch(handleHideCompletethisMounting());
        dispatch(handleHideCompleteEarningYourStudPage());
        setTimeout(() => {
          handleGetMinedDiamondFilters(
            paramdealerId,
            pageNo,
            pageSize,
            resetVal.saveSearchLab,
            filters
          );
        }, 100);

        setDealerId(paramdealerId);
        handleGetSocialDetails(paramdealerId);
      }
    } else {
      if (loginDetails) {
        let filters = filterVals;
        if (loginDetails.dealerId) {
          if (resetVal) {
            if (settingDetails) {
              if (settingDetails.selectedRow) {
                if (settingDetails.selectedRow.availableShapes) {
                  let availableShape = [];
                  let shapes = JSON.parse(
                    settingDetails.selectedRow.availableShapes
                  );
                  filters["Shape"] = availableShape;
                  setFilterVals(filters);
                }
              }
            }
            dispatch(handleHideCompletethisMounting());
            dispatch(handleHideCompleteEarningYourStudPage());
            handleGetMinedDiamondFilters(
              loginDetails.dealerId,
              pageNo,
              pageSize,
              resetVal.saveSearchLab,
              filters
            );
            setDealerId(loginDetails.dealerId);
            handleGetSocialDetails(loginDetails.dealerId);
          }
        }
      }
    }
  }, [resetVal.resetLab, resetVal.saveSearchLab]);

  const handleOpenCertificate = (link) => {
    window.open(
      link,
      "",
      "width=500,height=500, top=' + top + ', left=' + left"
    );
  };

  const handleOpenCertificateTab = (row) => {
    setRequestMoreCertModal(true);
    setDiamondInfoDetails(row);
  };

  const handleSubmitRequestCert = () => {
    handleSaveRequestCertificate();
  };

  const handleSaveRequestCertificate = async () => {
    const isValid = handleRequestCertificateValidation();
    if (!isValid) {
      return false;
    }
    try {
      let inputData = {
        dealerID: dealerId.toString(),
        did: diamondInfoDetails.intDiamondId,
        name: certInfoData.name,
        email: certInfoData.email,
        phone: certInfoData.phoneNumber,
        comments: certInfoData.comments,
        retailerID: diamondInfoDetails.DealerID,
      };

      setRequestMoreCertModalLoading(true);
      await SelectYourDiamondService.RingBuilderRequestCertificate(inputData)
        .then((response) => {
          let message = response.data.message;
          if (message == "Success") {
            NotificationManager.success(
              "Your request has been sent to GemFind. A representative will be contacting you shortly."
            );
            setcertInfoData(initialCertInfoData);
            setcertInfoDataValidation(initialCertInfoDataVal);
            setRequestMoreCertModalLoading(false);
            setRequestMoreCertModal(false);
          } else {
            NotificationManager.success(
              "Your request has been sent to GemFind. A representative will be contacting you shortly."
            );
            setcertInfoData(initialCertInfoData);
            setcertInfoDataValidation(initialCertInfoDataVal);
            setRequestMoreCertModalLoading(false);
            setRequestMoreCertModal(false);
          }
        })
        .catch((error) => {
          console.log(error);
          setcertInfoData(initialCertInfoData);
          setcertInfoDataValidation(initialCertInfoDataVal);
          setRequestMoreCertModalLoading(false);
          setRequestMoreCertModal(false);
        });
    } catch (error) {
      console.log(error);
      setcertInfoData(initialCertInfoData);
      setcertInfoDataValidation(initialCertInfoDataVal);
      setRequestMoreCertModalLoading(false);
      setRequestMoreCertModal(false);
    }
  };

  const handleRequestCertificateValidation = () => {
    const { name, email, phoneNumber } = certInfoData;
    const certInfoValidation = {
      nameVal: "",
      emailVal: "",
      phoneNumberVal: "",
    };
    let isValid = true;

    if (!name) {
      isValid = false;
      certInfoValidation.nameVal = "First Name is Compulsory";
    }

    let validRegex =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (!email) {
      isValid = false;
      certInfoValidation.emailVal = "Email address is compulsory";
    } else if (!email.match(validRegex)) {
      isValid = false;
      certInfoValidation.emailVal = "Email address is invalid";
    } else {
      certInfoValidation.emailVal = "";
    }

    if (!phoneNumber) {
      isValid = false;
      certInfoValidation.phoneNumberVal = "Phone number is compulsory";
    } else if (
      (phoneNumber.length >= 10 && phoneNumber.includes("-")) ||
      (phoneNumber.length >= 10 && phoneNumber.includes(",")) ||
      (phoneNumber.length >= 10 && phoneNumber.includes("+"))
    ) {
      certInfoValidation.phoneNumberVal = "";
    } else if (!/^(?!0{10,15})(\+\d{1,3}[-]?)?\d{10,15}$/.test(phoneNumber)) {
      isValid = false;
      certInfoValidation.phoneNumberVal = "Phone number is invalid";
    } else {
      certInfoValidation.phoneNumberVal = "";
    }
    if (!isValid) {
      setcertInfoDataValidation(certInfoValidation);
    }

    return isValid;
  };

  const preventPasteNegative = (e) => {
    const clipboardData = e.clipboardData || window.clipboardData;
    const pastedData = parseFloat(clipboardData.getData("text"));

    if (pastedData < 0) {
      e.preventDefault();
    }
  };

  const handleOnChange = (row, e) => {
    const temp = labDiamondList.map((x) => {
      if (x.intDiamondId == row.intDiamondId) {
        return { ...x, checkbox: e.target.checked == true ? true : false };
      } else return x;
    });

    let saveRow = temp.filter((obj) => obj.checkbox == true);
    setLabDiamondList(temp);
    let CompareIds = saveRow.map((x) => x.intDiamondId);
    let compareData = {
      ids: CompareIds,
      pageName: "Lab",
    };
    dispatch(handleComaparePageIds(CompareIds));
    dispatch(handleCompareKey("Lab"));
  };

  const onKeyPressMinusEvent = (e) => {
    if (e.code === "Minus") {
      e.preventDefault();
    }
    const keyCode = e.keyCode || e.which;
    const keyValue = String.fromCharCode(keyCode);
    if (!new RegExp(/^[+-]?\d*(?:[.,]\d*)?$/).test(keyValue))
      e.preventDefault();
    return;
  };

  const [rowData, setRowData] = useState({});

  const handleOnClick = (row) => {
    setRowData(row);
  };

  const columns = [
    {
      title: "",
      dataIndex: "checkbox",
      width: 50,
      render: (item, row) => {
        return (
          <div
            className="action__btns"
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <Checkbox
              name="checkbox"
              checkedChildren="Yes"
              unCheckedChildren="No"
              checked={row.checkbox == true ? true : false}
              onChange={(e) => handleOnChange(row, e, "checkbox")}
            />
          </div>
        );
      },
    },

    {
      title: "Shape",
      dataIndex: "txtShape",
      width: 120,
      sorter: (a, b) => {},
      showSorterTooltip: false,
      render: (item, row) => {
        return (
          <div className="shape__img">
            <img src={row.ImageFileName} alt="" />
            <span>{row.txtShape}</span>
          </div>
        );
      },
    },
    {
      title: "Carat",
      dataIndex: "fltCarat",
      sorter: (a, b) => {},
      showSorterTooltip: false,
    },
    {
      title: "Color",
      dataIndex: "txtColor",
      sorter: (a, b) => {},
      showSorterTooltip: false,
    },
    {
      title: "Clarity",
      dataIndex: "txtClarity",
      responsive: ["lg"],
      sorter: (a, b) => {},
      showSorterTooltip: false,
    },
    {
      title: "Cut",
      dataIndex: "txtCutGrade",
      responsive: ["lg"],
      sorter: (a, b) => {},
      showSorterTooltip: false,
    },
    {
      title: "Depth",
      dataIndex: "fltDepth",
      responsive: ["lg"],
      sorter: (a, b) => {},
      showSorterTooltip: false,
    },
    {
      title: "Table",
      dataIndex: "fltTable",
      responsive: ["lg"],
      sorter: (a, b) => {},
      showSorterTooltip: false,
    },
    {
      title: "Polish",
      dataIndex: "txtPolish",
      responsive: ["lg"],
      sorter: (a, b) => {},
      showSorterTooltip: false,
    },
    {
      title: "Sym.",
      dataIndex: "txtSymmetry",
      responsive: ["lg"],
      sorter: (a, b) => {},
      showSorterTooltip: false,
    },
    {
      title: "Measure.",
      responsive: ["lg"],
      width: 150,
      dataIndex: "txtMeasurements",
      sorter: (a, b) => {},
      showSorterTooltip: false,
    },
    {
      title: "Cert.",
      responsive: ["lg"],
      dataIndex: "txtCertificate",
      sorter: (a, b) => {},
      showSorterTooltip: false,
      render: (item, row) => {
        return (
          <div>
            {row.txtCertificate == "None" ? (
              <span
                onClick={() => handleOpenCertificateTab(row)}
                className="linkText"
              >
                {row.txtCertificate}
              </span>
            ) : (
              <span
                onClick={() => handleOpenCertificate(row.CERTLINK)}
                className="linkText"
              >
                {row.txtCertificate}
              </span>
            )}
          </div>
        );
      },
    },
    {
      title: `Price (${rowDetails?.currencycode ?? ""})`,
      dataIndex: "RealPrice",
      width: 150,
      sorter: (a, b) => {},
      showSorterTooltip: false,
      render: (item, row) => {
        return (
          <div>
            {callForPrice ? (
              <span>Call For Price</span>
            ) : (
              <span>
                {row.RealPrice == "0" ? (
                  <>
                    Call <span className="hideInMobile">For Price</span>
                  </>
                ) : (
                  <span>
                    {/* {row?.currencycode ?? ""} */}
                    {row?.CurrencySign}
                    {/* {row.currency ? row.currency : "$"}{" "} */}
                    {parseInt(row.RealPrice)
                      .toFixed()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                  </span>
                )}
              </span>
            )}
          </div>
        );
      },
    },
    {
      title: "Action",
      dataIndex: "action",
      width: 100,
      render: (index, row) => (
        <div>
          <div className="action__btns">
            <div className="image__block">
              <img
                src={Eye_icon}
                alt=""
                onClick={() => handleViewDetails(row)}
              />
            </div>
            <Popover content={content} trigger="hover" placement="left">
              <div className="image__block hideInMobile">
                <img
                  onMouseOver={() => handleOnClick(row)}
                  src={Info_icon}
                  alt=""
                />
              </div>
            </Popover>
          </div>
        </div>
      ),
    },
  ];

  const content = (
    <div className="info__tooltip">
      <p>
        <span>Depth</span>
        <span>:</span>
        <span>{rowData.fltDepth}</span>
      </p>
      <p>
        <span>Table</span>
        <span>:</span>
        <span>{rowData.fltTable}</span>
      </p>
      <p>
        <span>Polish</span>
        <span>:</span>
        <span>{rowData.txtPolish}</span>
      </p>
      <p>
        <span>Symmetry</span>
        <span>:</span>
        <span>{rowData.txtSymmetry}</span>
      </p>
      <p>
        <span>Measurement</span>
        <span>:</span>
        <span>{rowData.txtMeasurements}</span>
      </p>
    </div>
  );

  const data = [
    {
      key: "1",
      checkinpt: <input type="checkbox" />,
      image: <img src={Rectangle} className="prod__img" alt="" width="50px" />,
      Shape: <span>Oval</span>,
      SKU: <span>176152702</span>,
      Carat: <span>2.53</span>,
      Color: <span>G</span>,
      Clarity: <span>VVS2</span>,
      Cut: <span>Excellent</span>,
      InHouse: <span>Yes</span>,
      Depth: <span>60.2</span>,
      Table: <span>58</span>,
      Polish: <span>Excellent</span>,
      Measurement: <span>10.35X7.84X4.80</span>,
      Cert: <span>GIA</span>,
      Price: <span>$190.00</span>,
    },
  ];

  const { Option } = Select;
  const closeCertInfoModal = () => {
    setRequestMoreCertModal(false);
    setcertInfoData(initialCertInfoData);
    setcertInfoDataValidation(initialCertInfoDataVal);
  };

  const handleProductChangeOpen = (intDiamondId) => {
    let newproductChange = productChange;
    newproductChange[intDiamondId] = true;
    setProductChange((prevState) => ({
      ...prevState,
      ...newproductChange,
    }));
  };

  const handleProductChangeClose = (intDiamondId) => {
    let newproductChange = productChange;
    newproductChange[intDiamondId] = false;
    setProductChange((prevState) => ({
      ...prevState,
      ...newproductChange,
    }));
  };

  const handleViewChange = (viewname) => {
    setViewItem(viewname);
  };

  const handleScrollToTop = () => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  };

  const handleViewDetails = (details, type) => {
    let obj = details; 
    obj["pageName"] = "Lab";
    obj["shapeFilters"] = filterVals.Shape;
    localStorage.setItem("selectedDiamondData", JSON.stringify(obj));
    dispatch(handleViewDetailsPage(obj));
    dispatch(handleAddDiamondViewEdit());
    if (type == "addSetting") {
      navigate(
        "/chooseYourSetting" +
          `?ischooseYourSetting=True&isselectyourdiamond=false&DealerID=${paramdealerId}&DiamondId=${obj.intDiamondId}&pageName=${obj.pageName}&shapeFilters=${obj.txtShape}&txtCutlet=${obj.txtCutGrade}&txtClarity=${obj.txtClarity}&intColorPriority=${obj.intColorPriority}`
      );
    } else {
      navigate(
        "/viewDiamondDetails" +
          `?isSocialPage=True&isselectyourdiamond=false&DealerID=${paramdealerId}&diamondId=${obj.intDiamondId}&pageNo=1&isAllowed=True&pageName=${obj.pageName}&shapeFilters=${obj.txtShape}&txtCutlet=${obj.txtCutGrade}&txtClarity=${obj.txtClarity}&intColorPriority=${obj.intColorPriority}&isLab=True`
      );
    }
    dispatch(handleViewCompletethisMounting());
    handleScrollToTop();
    window.parent.postMessage("submitted form", "*");
  };
  const onKeyPressEvent1 = (event) => {
    const keyCode = event.keyCode || event.which;
    const keyValue = String.fromCharCode(keyCode);
    if (!new RegExp("[0-9.]").test(keyValue)) event.preventDefault();
    return;
  };

  const firstSliderPriceValue = (e, num) => {
    let newArr = [...priceValue];
    let Value = e.target.value && e.target.value.replace(currencySymbol, "");

    if (Number(parseFloat(Value.replace(/,/g, ""))) > state.PriceMax) {
      e.preventDefault();
    } else if (Number(num) == 0) {
      if (Value == "") {
        newArr[0] = 0;
        setPriceValue(newArr);
        let tempArray = filterVals;
        tempArray["PriceMin"] = 0;
        setFilterVals(tempArray);
        dispatch(handleSetSaveEveryTimeLabTab(tempArray));
      } else {
        if (newArr[0] == 0 && Value.includes(".")) {
          newArr[0] = Value;
          setPriceValue(newArr);
        } else {
          if (newArr[0] == 0) {
            newArr[0] = parseFloat(Value);
            setPriceValue(newArr);
          } else {
            newArr[0] = Value;
            setPriceValue(newArr);
          }
        }

        let tempArray = filterVals;
        tempArray["PriceMin"] = Value;
        setFilterVals(tempArray);
        dispatch(handleSetSaveEveryTimeLabTab(tempArray));
      }
    } else if (Number(num) == 1) {
      if (Value == "") {
        newArr[1] = 0;
        setPriceValue(newArr);
        let tempArray = filterVals;
        tempArray["PriceMax"] = 0;
        setFilterVals(tempArray);
        dispatch(handleSetSaveEveryTimeLabTab(tempArray));
      } else {
        if (newArr[1] == 0 && Value.includes(".")) {
          newArr[1] = Value;
          setPriceValue(newArr);
        } else {
          if (newArr[1] == 0) {
            newArr[1] = parseFloat(Value);
            setPriceValue(newArr);
          } else {
            newArr[1] = Value;
            setPriceValue(newArr);
          }
        }

        let tempArray = filterVals;
        tempArray["PriceMax"] = Value;
        setFilterVals(tempArray);
        dispatch(handleSetSaveEveryTimeLabTab(tempArray));
      }
    }
  };

  const handleGetSocialDetails = (id) => {
    try {
      let inputData = {
        dealerID: parseInt(id),
      };
      SelectYourDiamondService.GetsPendantBuilderOptiondetails(inputData)
        .then((response) => {
          let message = response.data.message;
          let responseData = response.data.responseData;
          if (message == "Success") {
            if (responseData.length > 0) {
              setCallForPrice(responseData[0].callForPricependant);
              setViewCartButton(responseData[0].showViewCartButtonPendant);
              setViewCartButtonUrl(responseData[0].diamondpendentshoppingurl);
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const firstsetCaratSlideValue = (e, num) => {
    setPageNo(1);
    let newArr = [...caratSlideValue];
    let StrValue = e.target.value && e.target.value.replace("$", "");
    let Value = StrValue.replace(/,/g, "");
    if (Number(parseFloat(e.target.value.replace(/,/g, ""))) > state.CaratMax) {
      e.preventDefault();
    } else if (Number(num) == 0) {
      if (e.target.value == "") {
        newArr[0] = 0;
        setCaratSlideValue(newArr);
        let tempArray = filterVals;
        tempArray["CaratMin"] = 0;
        setFilterVals(tempArray);
        dispatch(handleSetSaveEveryTimeLabTab(tempArray));
      } else {
        if (newArr[0] == 0 && Value.includes(".")) {
          newArr[0] = Value;
          setCaratSlideValue(newArr);
        } else {
          if (newArr[0] == 0) {
            newArr[0] = parseFloat(Value);
            setCaratSlideValue(newArr);
          } else {
            newArr[0] = Value;
            setCaratSlideValue(newArr);
          }
        }

        let tempArray = filterVals;
        tempArray["CaratMin"] = Value;
        setFilterVals(tempArray);
        dispatch(handleSetSaveEveryTimeLabTab(tempArray));
      }
    } else if (Number(num) == 1) {
      if (e.target.value == "") {
        newArr[1] = 0;
        setCaratSlideValue(newArr);
        let tempArray = filterVals;
        tempArray["CaratMax"] = 0;
        setFilterVals(tempArray);
        dispatch(handleSetSaveEveryTimeLabTab(tempArray));
      } else {
        if (newArr[1] == 0 && Value.includes(".")) {
          newArr[1] = Value;
          setCaratSlideValue(newArr);
        } else {
          if (newArr[1] == 0) {
            newArr[1] = parseFloat(Value);
            setCaratSlideValue(newArr);
          } else {
            newArr[1] = Value;
            setCaratSlideValue(newArr);
          }
        }

        let tempArray = filterVals;
        tempArray["CaratMax"] = Value;
        setFilterVals(tempArray);
        dispatch(handleSetSaveEveryTimeLabTab(tempArray));
      }
    }
  };

  const handleCaratsOnBlur = () => {
    handleGetLabDiamondList(
      dealerId,
      1,
      pageSize,
      filterVals.Shape,
      priceValue[0],
      priceValue[1],
      caratSlideValue[0],
      caratSlideValue[1],
      state.Color,
      state.Clarity,
      state.Cut,
      state.Polish,
      state.Symmetry,
      state.Fluorescence,
      filterVals.DepthMin,
      filterVals.DepthMax,
      filterVals.TableMin,
      filterVals.TableMax,
      filterVals.Certificates, // Certificate
      searchSku, //Sku
      filterVals.Color, // color
      filterVals.Clarity, // clarity
      filterVals.Cut, // cut
      filterVals.Polish, // polish
      filterVals.Symmetry, //symmetry
      filterVals.Fluorescence, //fluorescence
      true, //isFilter
      sortColumnName,
      sortType,
      "filter"
    );
  };

  const handlePriceOnBlur = () => {
    handleGetLabDiamondList(
      dealerId,
      1,
      pageSize,
      filterVals.Shape,
      priceValue[0],
      priceValue[1],
      caratSlideValue[0],
      caratSlideValue[1],
      state.Color,
      state.Clarity,
      state.Cut,
      state.Polish,
      state.Symmetry,
      state.Fluorescence,
      filterVals.DepthMin,
      filterVals.DepthMax,
      filterVals.TableMin,
      filterVals.TableMax,
      filterVals.Certificates, // Certificate
      searchSku, //Sku
      filterVals.Color, // color
      filterVals.Clarity, // clarity
      filterVals.Cut, // cut
      filterVals.Polish, // polish
      filterVals.Symmetry, //symmetry
      filterVals.Fluorescence, //fluorescence
      true, //isFilter
      sortColumnName,
      sortType,
      "filter"
    );
  };

  const handleOnBlur = (val, name) => {
    if (name == "DepthMin") {
      handleGetLabDiamondList(
        dealerId,
        1,
        pageSize,
        filterVals.Shape,
        priceValue[0],
        priceValue[1],
        caratSlideValue[0],
        caratSlideValue[1],
        state.Color,
        state.Clarity,
        state.Cut,
        state.Polish,
        state.Symmetry,
        state.Fluorescence,
        val,
        filterVals.DepthMax,
        filterVals.TableMin,
        filterVals.TableMax,
        filterVals.Certificate, // Certificate
        searchSku, //Sku
        filterVals.Color, // color
        filterVals.Clarity, // clarity
        filterVals.Cut, // cut
        filterVals.Polish, // polish
        filterVals.Symmetry, //symmetry
        filterVals.Fluorescence, //fluorescence
        true, // isFilter,
        sortColumnName,
        sortType,
        "filter"
      );
    }
    if (name == "DepthMax") {
      handleGetLabDiamondList(
        dealerId,
        1,
        pageSize,
        filterVals.Shape,
        priceValue[0],
        priceValue[1],
        caratSlideValue[0],
        caratSlideValue[1],
        state.Color,
        state.Clarity,
        state.Cut,
        state.Polish,
        state.Symmetry,
        state.Fluorescence,
        filterVals.DepthMin,
        val,
        filterVals.TableMin,
        filterVals.TableMax,
        filterVals.Certificate, // Certificate
        searchSku, //Sku
        filterVals.Color, // color
        filterVals.Clarity, // clarity
        filterVals.Cut, // cut
        filterVals.Polish, // polish
        filterVals.Symmetry, //symmetry
        filterVals.Fluorescence, //fluorescence
        true, // isFilter,
        sortColumnName,
        sortType,
        "filter"
      );
    }
    if (name == "TableMin") {
      handleGetLabDiamondList(
        dealerId,
        1,
        pageSize,
        filterVals.Shape,
        priceValue[0],
        priceValue[1],
        caratSlideValue[0],
        caratSlideValue[1],
        state.Color,
        state.Clarity,
        state.Cut,
        state.Polish,
        state.Symmetry,
        state.Fluorescence,
        filterVals.DepthMin,
        filterVals.DepthMax,
        val,
        filterVals.TableMax,
        filterVals.Certificate, // Certificate
        searchSku, //Sku
        filterVals.Color, // color
        filterVals.Clarity, // clarity
        filterVals.Cut, // cut
        filterVals.Polish, // polish
        filterVals.Symmetry, //symmetry
        filterVals.Fluorescence, //fluorescence
        true, // isFilter,
        sortColumnName,
        sortType,
        "filter"
      );
    }
    if (name == "TableMax") {
      handleGetLabDiamondList(
        dealerId,
        1,
        pageSize,
        filterVals.Shape,
        priceValue[0],
        priceValue[1],
        caratSlideValue[0],
        caratSlideValue[1],
        state.Color,
        state.Clarity,
        state.Cut,
        state.Polish,
        state.Symmetry,
        state.Fluorescence,
        filterVals.DepthMin,
        filterVals.DepthMax,
        filterVals.TableMin,
        val,
        filterVals.Certificate, // Certificate
        searchSku, //Sku
        filterVals.Color, // color
        filterVals.Clarity, // clarity
        filterVals.Cut, // cut
        filterVals.Polish, // polish
        filterVals.Symmetry, //symmetry
        filterVals.Fluorescence, //fluorescence
        true, // isFilter,
        sortColumnName,
        sortType,
        "filter"
      );
    }
  };

  const handleCertInfoChange = (e, name, type) => {
    setcertInfoData({
      ...certInfoData,
      [name]: e.target.value,
    });
    if (name == "name") {
      setcertInfoDataValidation((prevdata) => ({
        ...prevdata,
        nameVal: "",
      }));
    }
    if (name == "email") {
      setcertInfoDataValidation((prevdata) => ({
        ...prevdata,
        emailVal: "",
      }));
    }
    if (name == "phoneNumber") {
      setcertInfoDataValidation((prevdata) => ({
        ...prevdata,
        phoneNumberVal: "",
      }));
    }
  };
  function removeSpecialCharactersAndConvertToNumber(inputString) {
    const cleanedString = inputString.replace(/[^0-9.]/g, "");
    // Remove a trailing hyphen, if present
    const cleanedStringWithoutHyphen = cleanedString.replace(/-$/, "");
    // Convert the cleaned string to a number
    return parseFloat(cleanedStringWithoutHyphen, 10);
  }

  const handleLabGrownFilterChange = (val, name, type) => {
    setPageNo(1);
    if (type == "filter") {
      let tempArray = filterVals[name];
      if (tempArray.includes(val)) {
        tempArray = tempArray.filter((item) => item != val);
      } else {
        tempArray.push(val);
      }
      setFilterVals({
        ...filterVals,
        [name]: tempArray,
      });
      handleScrollToTop();
      if (name == "Shape") {
        handleGetLabDiamondList(
          dealerId,
          1,
          pageSize,
          tempArray,
          priceValue[0],
          priceValue[1],
          caratSlideValue[0],
          caratSlideValue[1],
          state.Color,
          state.Clarity,
          state.Cut,
          state.Polish,
          state.Symmetry,
          state.Fluorescence,
          filterVals.DepthMin,
          filterVals.DepthMax,
          filterVals.TableMin,
          filterVals.TableMax,
          filterVals.Certificates, // Certificate
          searchSku, //Sku
          filterVals.Color, // color
          filterVals.Clarity, // clarity
          filterVals.Cut, // cut
          filterVals.Polish, // polish
          filterVals.Symmetry, //symmetry
          filterVals.Fluorescence, //fluorescence
          true, //isFilter
          sortColumnName,
          sortType,
          "filter"
        );
      }

      if (name == "Cut") {
        handleGetLabDiamondList(
          dealerId,
          1,
          pageSize,
          filterVals.Shape,
          priceValue[0],
          priceValue[1],
          caratSlideValue[0],
          caratSlideValue[1],
          state.Color,
          state.Clarity,
          state.Cut,
          state.Polish,
          state.Symmetry,
          state.Fluorescence,
          filterVals.DepthMin,
          filterVals.DepthMax,
          filterVals.TableMin,
          filterVals.TableMax,
          filterVals.Certificates, // Certificate
          searchSku, //Sku
          filterVals.Color, // color
          filterVals.Clarity, // clarity
          tempArray, //filterVals.Cut, // cut
          filterVals.Polish, // polish
          filterVals.Symmetry, //symmetry
          filterVals.Fluorescence, //fluorescence
          true, //isFilter
          sortColumnName,
          sortType,
          "filter"
        );
      }
      if (name == "Color") {
        handleGetLabDiamondList(
          dealerId,
          1,
          pageSize,
          filterVals.Shape,
          priceValue[0],
          priceValue[1],
          caratSlideValue[0],
          caratSlideValue[1],
          state.Color,
          state.Clarity,
          state.Cut,
          state.Polish,
          state.Symmetry,
          state.Fluorescence,
          filterVals.DepthMin,
          filterVals.DepthMax,
          filterVals.TableMin,
          filterVals.TableMax,
          filterVals.Certificates, // Certificate
          searchSku, //Sku
          tempArray, // color
          filterVals.Clarity, // clarity
          filterVals.Cut, // cut
          filterVals.Polish, // polish
          filterVals.Symmetry, //symmetry
          filterVals.Fluorescence, //fluorescence
          true, //isFilter
          sortColumnName,
          sortType,
          "filter"
        );
      }
      if (name == "Clarity") {
        handleGetLabDiamondList(
          dealerId,
          1,
          pageSize,
          filterVals.Shape,
          priceValue[0],
          priceValue[1],
          caratSlideValue[0],
          caratSlideValue[1],
          state.Color,
          state.Clarity,
          state.Cut,
          state.Polish,
          state.Symmetry,
          state.Fluorescence,
          filterVals.DepthMin,
          filterVals.DepthMax,
          filterVals.TableMin,
          filterVals.TableMax,
          filterVals.Certificates, // Certificate
          searchSku, //Sku
          filterVals.Color, // color
          tempArray, // clarity
          filterVals.Cut, // cut
          filterVals.Polish, // polish
          filterVals.Symmetry, //symmetry
          filterVals.Fluorescence, //fluorescence
          true, //isFilter
          sortColumnName,
          sortType,
          "filter"
        );
      }
      if (name == "Polish") {
        handleGetLabDiamondList(
          dealerId,
          1,
          pageSize,
          filterVals.Shape,
          priceValue[0],
          priceValue[1],
          caratSlideValue[0],
          caratSlideValue[1],
          state.Color,
          state.Clarity,
          state.Cut,
          state.Polish,
          state.Symmetry,
          state.Fluorescence,
          filterVals.DepthMin,
          filterVals.DepthMax,
          filterVals.TableMin,
          filterVals.TableMax,
          filterVals.Certificates, // Certificate
          searchSku, //Sku
          filterVals.Color, // color
          filterVals.Clarity, // clarity
          filterVals.Cut, // cut
          tempArray, // polish
          filterVals.Symmetry, //symmetry
          filterVals.Fluorescence, //fluorescence
          true, //isFilter
          sortColumnName,
          sortType,
          "filter"
        );
      }
      if (name == "Fluorescence") {
        handleGetLabDiamondList(
          dealerId,
          1,
          pageSize,
          filterVals.Shape,
          priceValue[0],
          priceValue[1],
          caratSlideValue[0],
          caratSlideValue[1],
          state.Color,
          state.Clarity,
          state.Cut,
          state.Polish,
          state.Symmetry,
          state.Fluorescence,
          filterVals.DepthMin,
          filterVals.DepthMax,
          filterVals.TableMin,
          filterVals.TableMax,
          filterVals.Certificates, // Certificate
          searchSku, //Sku
          filterVals.Color, // color
          filterVals.Clarity, // clarity
          filterVals.Cut, // cut
          filterVals.Polish, // polish
          filterVals.Symmetry, //symmetry
          tempArray, //fluorescence
          true, //isFilter
          sortColumnName,
          sortType,
          "filter"
        );
      }
      if (name == "Symmetry") {
        handleGetLabDiamondList(
          dealerId,
          1,
          pageSize,
          filterVals.Shape,
          priceValue[0],
          priceValue[1],
          caratSlideValue[0],
          caratSlideValue[1],
          state.Color,
          state.Clarity,
          state.Cut,
          state.Polish,
          state.Symmetry,
          state.Fluorescence,
          filterVals.DepthMin,
          filterVals.DepthMax,
          filterVals.TableMin,
          filterVals.TableMax,
          filterVals.Certificates, // Certificate
          searchSku, //Sku
          filterVals.Color, // color
          filterVals.Clarity, // clarity
          filterVals.Cut, // cut
          filterVals.Polish, // polish
          tempArray, //symmetry
          filterVals.Fluorescence, //fluorescence
          true, //isFilter
          sortColumnName,
          sortType,
          "filter"
        );
      }
    } else {
      if (
        name == "DepthMin" ||
        name == "DepthMax" ||
        name == "TableMin" ||
        name == "TableMax"
      ) {
        if (val > 100 || val < 0) {
          return false;
        }
        setFilterVals({
          ...filterVals,
          [name]: removeSpecialCharactersAndConvertToNumber(val),
        });
      } else {
        setFilterVals({
          ...filterVals,
          [name]: val,
        });
      }
      if (name == "Certificates") {
        let allFilters = [];

        if (val.includes("all")) {
          allFilters = state.Certificates.map((cert) => {
            return cert.value;
          });
          allFilters.push("all");
          setSelectedCertificates(allFilters);
        } else if (val.length == state.Certificates.length) {
          allFilters = state.Certificates.map((cert) => {
            return cert.value;
          });
          allFilters.push("all");
          setSelectedCertificates(allFilters);
        } else {
          setSelectedCertificates(val);
        }
        handleGetLabDiamondList(
          dealerId,
          1,
          pageSize,
          filterVals.Shape,
          priceValue[0],
          priceValue[1],
          caratSlideValue[0],
          caratSlideValue[1],
          state.Color,
          state.Clarity,
          state.Cut,
          state.Polish,
          state.Symmetry,
          state.Fluorescence,
          filterVals.DepthMin,
          filterVals.DepthMax,
          filterVals.TableMin,
          filterVals.TableMax,
          val, // Certificate
          searchSku, //Sku
          filterVals.Color, // color
          filterVals.Clarity, // clarity
          filterVals.Cut, // cut
          filterVals.Polish, // polish
          filterVals.Symmetry, //symmetry
          filterVals.Fluorescence, //fluorescence
          true, //isFilter
          sortColumnName,
          sortType,
          "filter"
        );
      }
    }
    dispatch(handleSetSaveEveryTimeLabTab(filterVals));
  };

  const handleInputDiamondDataChange = (e, name) => {
    setDiamondFormData({
      ...diamondFormData,
      [name]: e.target.value,
    });
    if (name == "firstName") {
      setValidations((prevdata) => ({
        ...prevdata,
        firstNameVal: "",
      }));
    }
    if (name == "lastName") {
      setValidations((prevdata) => ({
        ...prevdata,
        lastNameVal: "",
      }));
    }
    if (name == "phoneNumber") {
      setValidations((prevdata) => ({
        ...prevdata,
        phoneNumberVal: "",
      }));
    }
    if (name == "emailAddress") {
      setValidations((prevdata) => ({
        ...prevdata,
        emailAddressVal: "",
      }));
    }
    if (name == "comments") {
      setValidations((prevdata) => ({
        ...prevdata,
        commentsVal: "",
      }));
    }
  };

  const handleInputDiamondDataReset = () => {
    setDiamondFormData(initialDiamondFormData);
    setValidations(initialDiamondFormDataVal);
  };

  const handleInputDiamondDataRequest = () => {
    let arrShapes =
      filterVals.Shape.length > 0 ? filterVals.Shape.map((x) => x) : null;
    let arrCutGrades =
      state.Cut.length > 0 ? state.Cut.map((x) => Number(x.value)) : null;
    let arrColors =
      state.Color.length > 0 ? state.Color.map((x) => Number(x.value)) : null;
    let arrPolishs =
      state.Polish.length > 0 ? state.Polish.map((x) => Number(x.value)) : null;
    let arrClaritys =
      state.Clarity.length > 0
        ? state.Clarity.map((x) => Number(x.value))
        : null;
    const isValid = handleValidation();
    if (!isValid) {
      return false;
    }

    try {
      let inputData = {
        dealerID: dealerId.toString(),
        firstName: diamondFormData.firstName,
        lastName: diamondFormData.lastName,
        phoneNumber: diamondFormData.phoneNumber,
        emailAddress: diamondFormData.emailAddress,
        comment: diamondFormData.comments,
        intDealerID: dealerId.toString(),
        subject: "",
        shapeList: arrShapes !== null ? arrShapes.toString() : "",
        priceMin: filterVals.PriceMin.toString(),
        priceMax: filterVals.PriceMax.toString(),
        caratMin: filterVals.CaratMin.toString(),
        caratMax: filterVals.CaratMax.toString(),
        colorMin: arrColors !== null ? Math.min(...arrColors).toString() : "",
        colorMax: arrColors !== null ? Math.max(...arrColors).toString() : "",
        clarityMin:
          arrClaritys !== null ? Math.min(...arrClaritys).toString() : "",
        clarityMax:
          arrClaritys !== null ? Math.max(...arrClaritys).toString() : "",
        cutgradeMin:
          arrCutGrades !== null ? Math.min(...arrCutGrades).toString() : "",
        cutgradeMax:
          arrCutGrades !== null ? Math.max(...arrCutGrades).toString() : "",
        certificate: filterVals.Certificates + "", //Certificate
        depthMin: filterVals.DepthMin.toString(),
        depthMax: filterVals.DepthMax.toString(),
        tableMin: filterVals.TableMin.toString(),
        tableMax: filterVals.TableMax.toString(),
        polishList:
          filterVals.Polish.length > 0 ? filterVals.Polish.toString() : "",
        symmetryList:
          filterVals.Symmetry.length > 0 ? filterVals.Symmetry.toString() : "",
        fluorescenceList:
          filterVals.Fluorescence.length > 0
            ? filterVals.Fluorescence.toString()
            : "",
        cutGradeList:
          filterVals.Cut.length > 0 ? filterVals.Cut.toString() : "",
        colorList:
          filterVals.Color.length > 0 ? filterVals.Color.toString() : "",
        clarityList:
          filterVals.Clarity.length > 0 ? filterVals.Clarity.toString() : "",
      };
      setReqDiamondLoading(true);
      SelectYourDiamondService.SendMailToRetailerForNoResults(inputData)
        .then((response) => {
          let message = response.data.message;
          let responseData = response.data.responseData;
          if (message == "Success") {
            setReqDiamondLoading(false);
            setDiamondFormData(initialDiamondFormData);
            setReqModalDataForApiRes({
              show: true,
              success: true,
              msg: "You Have Successully Requested For Diamond.",
            });
          } else {
            setReqDiamondLoading(false);
            setReqModalDataForApiRes({
              show: true,
              success: false,
              msg: "Cannot Request For Diamond.",
            });
          }
        })
        .catch((error) => {
          console.log(error);
          setReqDiamondLoading(false);
          NotificationManager.error("Sorry We Had An Error.");
        });
    } catch (error) {
      console.log(error);
      setReqDiamondLoading(false);
      NotificationManager.error("Sorry We Had An Error.");
    }
  };
  const handleGetMinedDiamondFilters = (id, pageNo, pageSize, val, filters) => {
    try {
      let inputData = {
        dealerID: id.toString(),
      };
      setFilterLoading(true);
      setLoading(true);
      SelectYourDiamondService.GetAvailablePendantbuilderFilters(inputData)
        .then((response) => {
          let message = response.data.message;
          let responseData =
            response.data.responseData.availableFilters_Response;
          let tempAvailableFilterData = state;
          let tempAvailableFilterValData = filterVals;
          if (message == "Success") {
            if (responseData) {
              tempAvailableFilterData.Shape =
                responseData.ringBuilderSettingsShape;
              tempAvailableFilterData.Cut =
                responseData.ringBuildersettingsCuts;
              tempAvailableFilterData.Color =
                responseData.ringBuildersettingsColor;
              tempAvailableFilterData.Clarity =
                responseData.ringBuildersettingsClarity;
              tempAvailableFilterData.Polish =
                responseData.ringBuildersettingsPolish;
              tempAvailableFilterData.Fluorescence =
                responseData.ringBuildersettingsFluorescence;
              tempAvailableFilterData.Symmetry =
                responseData.ringBuildersettingsSymmetry;
              tempAvailableFilterData.Certificates =
                responseData.ringBuildersettingsCertificates;
              tempAvailableFilterData.Clarity =
                responseData.ringBuildersettingsClarity;
              setCurrencySymbol(responseData.currencySign);
              setIsAdvance(responseData.advanced);
              setCaratSlideValue([0, 22]);
              setPriceValue([0, 1000000]);
              setShowAdvanced(responseData.advancedOption);
              setState({
                ...state,
                ...tempAvailableFilterData,
              });

              setFilterVals({
                ...filterVals,
                ...tempAvailableFilterValData,
              });
              let saveSearchObj = resetVal.LabDiamondFilter;

              if (val == true) {
                setFilterVals(saveSearchObj);
                setPriceValue([saveSearchObj.PriceMin, saveSearchObj.PriceMax]);
                setCaratSlideValue([
                  saveSearchObj.CaratMin,
                  saveSearchObj.CaratMax,
                ]);
              } else {
                setFilterVals({
                  ...filterVals,
                  ...tempAvailableFilterValData,
                });
                let tempObj = tempAvailableFilterValData;
                tempAvailableFilterValData.Shape = [];
                tempAvailableFilterValData.Cut = [];
                tempAvailableFilterValData.Color = [];
                tempAvailableFilterValData.Clarity = [];
                tempAvailableFilterValData.Polish = [];
                tempAvailableFilterValData.Fluorescence = [];
                tempAvailableFilterValData.Symmetry = [];
                tempAvailableFilterValData.Intensity = [];
                tempAvailableFilterValData.Certificates = [];
                tempAvailableFilterValData.PriceMin = 0;
                tempAvailableFilterValData.PriceMax = 1000000;
                tempAvailableFilterValData.CaratMin = 0;
                tempAvailableFilterValData.CaratMax = 22;
                tempAvailableFilterValData.TableMin = 0;
                tempAvailableFilterValData.TableMax = 100;
                tempAvailableFilterValData.DepthMin = 0;
                tempAvailableFilterValData.DepthMax = 100;

                tempObj["disabled"] = {};
                setFilterVals({
                  ...filterVals,
                  ...tempObj,
                });

                dispatch(handleSetSaveFilterLabTab(tempObj));
              }

              setFilterLoading(false);

              if (val == true) {
                handleGetLabDiamondList(
                  id,
                  pageNo,
                  pageSize,
                  saveSearchObj.Shape, //shapes
                  saveSearchObj.PriceMin,
                  saveSearchObj.PriceMax,
                  saveSearchObj.CaratMin,
                  saveSearchObj.CaratMax,
                  responseData.sldColorMax,
                  responseData.clarityMax,
                  responseData.sldCutGradeMax,
                  responseData.sldPolishMax,
                  responseData.sldSymmetryMax,
                  responseData.sldFluorescenceMax,
                  saveSearchObj.DepthMin,
                  saveSearchObj.DepthMax,
                  saveSearchObj.TableMin,
                  saveSearchObj.TableMax,
                  saveSearchObj.Certificate, // Certificate
                  searchSku, //Sku
                  saveSearchObj.Color, // color
                  saveSearchObj.Clarity, // clarity
                  saveSearchObj.Cut, // cut
                  saveSearchObj.Polish, // polish
                  saveSearchObj.Symmetry, //symmetry
                  saveSearchObj.Fluorescence, //fluorescence
                  true, //isFilter
                  sortColumnName,
                  sortType
                );
              } else {
                setSearchSku("");
                handleGetLabDiamondList(
                  id,
                  pageNo,
                  pageSize,
                  filters ? filters.Shape : [], //shapes
                  responseData.priceMin,
                  responseData.priceMax,
                  responseData.caratMin,
                  responseData.caratMax,
                  responseData.colorMax,
                  responseData.c,
                  responseData.sldCutGradeMax,
                  responseData.sldPolishMax,
                  responseData.sldSymmetryMax,
                  responseData.sldFluorescenceMax,
                  responseData.sldDepthMin,
                  responseData.sldDepthMax,
                  responseData.sldTableMin,
                  responseData.sldTableMax,
                  "", // Certificate
                  "", //Sku
                  [], // color
                  [], // clarity
                  [], // cut
                  [], // polish
                  [], //symmetry
                  [], //fluorescence
                  false, // isFilter
                  sortColumnName,
                  sortType
                );
              }
            } else {
            }
          } else {
            setLabDiamondList([]);
            setTotalRowCount(0);
            setFilterLoading(false);
          }
        })
        .catch((error) => {
          console.log(error);
          setFilterLoading(false);
        });
    } catch (error) {
      console.log(error);
      setFilterLoading(false);
    }
  };

  const handleGetLabDiamondList = (
    id,
    PageNo,
    PageSize,
    shapes,
    priceMin,
    priceMax,
    caratmin,
    caratmax,
    allColor,
    allClarity,
    allCut,
    allPolish,
    allSymmetry,
    allFluorescence,
    depthmin,
    depthmax,
    tablemin,
    tablemax,
    Certificate, // Certificate
    Sku, //Sku
    colorVal, // color
    clarityVal, // clarity
    cutVal, // cut
    polishVal, // polish
    symmetryVal, //symmetry
    fluorescenceVal, //fluorescence
    filter, // isFilter
    column,
    type
  ) => {
    let arrShapes = shapes.length > 0 ? shapes.map((x) => x) : null;
    let arrCutGrades =
      allCut && allCut.length > 0 ? allCut.map((x) => Number(x.value)) : null;
    let arrColors =
      allColor && allColor.length > 0
        ? allColor.map((x) => Number(x.value))
        : null;
    let arrPolishs =
      allPolish && allPolish.length > 0
        ? allPolish.map((x) => Number(x.value))
        : null;
    let arrClaritys =
      allClarity && allClarity.length > 0
        ? allClarity.map((x) => Number(x.value))
        : null;
    let arrSymmetrys =
      allSymmetry && allSymmetry.length > 0
        ? allSymmetry.map((x) => Number(x.value))
        : null;
    let arrFluorescences =
      allFluorescence && allFluorescence.length > 0
        ? allFluorescence.map((x) => Number(x.value))
        : null;

    try {
      let inputData = {
        dealerID: id.toString(),
        shapeList: arrShapes !== null ? arrShapes.toString() : "",
        priceMin: priceMin.toString().replace(/,/g, ""),
        priceMax: priceMax.toString().replace(/,/g, ""),
        caratMin: caratmin.toString(),
        caratMax: caratmax.toString(),
        colorMin: arrColors !== null ? Math.min(...arrColors).toString() : "",
        colorMax: arrColors !== null ? Math.max(...arrColors).toString() : "",
        clarityMin:
          arrClaritys !== null && arrClaritys.length
            ? Math.min(...arrClaritys).toString()
            : "",
        clarityMax:
          arrClaritys !== null && arrClaritys.length
            ? Math.max(...arrClaritys).toString()
            : "",
        cutgradeMin:
          arrCutGrades !== null && arrCutGrades
            ? Math.min(...arrCutGrades).toString()
            : "",
        cutgradeMax:
          arrCutGrades !== null && arrCutGrades
            ? Math.max(...arrCutGrades).toString()
            : "",
        symmetryMin:
          arrSymmetrys !== null && arrSymmetrys
            ? Math.min(...arrSymmetrys).toString()
            : "",
        symmetryMax:
          arrSymmetrys !== null && arrSymmetrys
            ? Math.max(...arrSymmetrys).toString()
            : "",
        polishMin:
          arrPolishs !== null && arrPolishs
            ? Math.min(...arrPolishs).toString()
            : "",
        polishMax:
          arrPolishs !== null && arrPolishs
            ? Math.max(...arrPolishs).toString()
            : "",
        fluorescenceMin:
          arrFluorescences !== null && arrFluorescences
            ? Math.min(...arrFluorescences).toString()
            : "",
        fluorescenceMax:
          arrFluorescences !== null && arrFluorescences
            ? Math.max(...arrFluorescences).toString()
            : "",
        certificate:
          Certificate && Certificate.length > 0 ? Certificate.toString() : "",
        skuNo: Sku.toString(),
        pairCode: "",
        pageSize: PageSize.toString(),
        currentPage: PageNo.toString(),
        orderBy:
          column == "txtShape"
            ? "Cut"
            : column == "fltCarat"
            ? "Size"
            : column == "txtColor"
            ? "Color"
            : column == "txtClarity"
            ? "Clarity"
            : column == "txtCutGrade"
            ? "CutGrade"
            : column == "fltDepth"
            ? "Depth"
            : column == "fltTable"
            ? "TableMeasure"
            : column == "txtPolish"
            ? "Polish"
            : column == "txtSymmetry"
            ? "Symmetry"
            : column == "txtMeasurements"
            ? "Measurements"
            : column == "txtCertificate"
            ? "Certificate"
            : column == "fltPrice"
            ? "FltPrice"
            : "FltPrice",
        orderType: type ? type : "ASC",
        detailsLink: "pendantBuilderDiamondDetail.aspx",
        DID: "",
        depthMin: depthmin ? depthmin.toString() : "",
        depthMax: depthmax ? depthmax.toString() : "",
        tableMin: tablemin ? tablemin.toString() : "",
        tableMax: tablemax ? tablemax.toString() : "",
        caratPriceMin: "",
        caratPriceMax: "",
        ThemeName: "WhiteTheme",
        ShowPrice: "false",
        BindWhat: "All",
        polishList:
          polishVal && polishVal.length > 0 ? polishVal.toString() : "",
        SymmetryList:
          symmetryVal && symmetryVal.length > 0 ? symmetryVal.toString() : "",
        FluorescenceList:
          fluorescenceVal && fluorescenceVal.length > 0
            ? fluorescenceVal.toString()
            : "",
        ExtraQStr: "&SID=&Shape=&CTW=&Size=",
        CutGradeList: cutVal && cutVal.length > 0 ? cutVal.toString() : "",
        ColorList: colorVal && colorVal.length > 0 ? colorVal.toString() : "",
        ClarityList:
          clarityVal && clarityVal.length > 0 ? clarityVal.toString() : "",
        BindCheckbox: "1",
        SOrigin: "",
        Isdiamond: 0,
        CustID: "",
        Track: "0",
        CountryTaxRate: "",
        CountryCode: "",
        IsFB: 0,
        IsMLPB: 0,
      };

      setLoading(true);
      if (filter) {
        setFilterLoading(true);
      }
      SelectYourDiamondService.PendantBuilderLabGrownDiamonds(inputData)
        .then((response) => {
          let message = response.data.message;
          let responseData = response.data.responseData.Table1;
          let FirstRow = response.data.responseData.Table1[0];

          if (message == "Success") {
            if (responseData.length > 0) {
              setLabDiamondList(responseData);
              setTotalRowCount(responseData[0].intTotalRecords);
              setRowDetails(FirstRow);
              setLoading(false);
              setCount(1);
            } else {
              setLabDiamondList([]);
              setTotalRowCount(0);
              setRowDetails({});
              setLoading(false);
              setCount(0);
            }
          } else {
            setLabDiamondList([]);
            setTotalRowCount(0);
            setRowDetails({});
            setLoading(false);
            setCount(0);
          }
          setShowAllContent(false);
          setFilterLoading(false);
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
          setShowAllContent(false);
          setFilterLoading(false);
        });
    } catch (error) {
      console.log(error);
      setLoading(false);
      setShowAllContent(false);
      setFilterLoading(false);
    }
  };

  function onChange(page, pageSize) {
    let filter = [];
    if (page !== pageNo) {
      setPageNo(page);
      handleGetLabDiamondList(
        dealerId,
        page,
        pageSize,
        filterVals.Shape,
        priceValue[0],
        priceValue[1],
        caratSlideValue[0],
        caratSlideValue[1],
        state.Color,
        state.Clarity,
        state.Cut,
        state.Polish,
        state.Symmetry,
        state.Fluorescence,
        filterVals.DepthMin,
        filterVals.DepthMax,
        filterVals.TableMin,
        filterVals.TableMax,
        filterVals.Certificates, // Certificate
        searchSku, //Sku
        filterVals.Color, // color
        filterVals.Clarity, // clarity
        filterVals.Cut, // cut
        filterVals.Polish, // polish
        filterVals.Symmetry, //symmetry
        filterVals.Fluorescence, //fluorescence
        filter, //isFilter
        sortColumnName,
        sortType
      );
    }
  }
  function onShowSizeChange(current, pageNewSize) {
    let filter = [];
    if (pageNewSize !== pageSize) {
      setPageSize(pageNewSize);
      handleGetLabDiamondList(
        dealerId,
        current,
        pageNewSize,
        filterVals.Shape,
        priceValue[0],
        priceValue[1],
        caratSlideValue[0],
        caratSlideValue[1],
        state.Color,
        state.Clarity,
        state.Cut,
        state.Polish,
        state.Symmetry,
        state.Fluorescence,
        filterVals.DepthMin,
        filterVals.DepthMax,
        filterVals.TableMin,
        filterVals.TableMax,
        filterVals.Certificates, // Certificate
        searchSku, //Sku
        filterVals.Color, // color
        filterVals.Clarity, // clarity
        filterVals.Cut, // cut
        filterVals.Polish, // polish
        filterVals.Symmetry, //symmetry
        filterVals.Fluorescence, //fluorescence
        filter, // isFilter
        sortColumnName,
        sortType
      );
    }
  }

  const handleSetRowDetils = (e, record) => {
    setRowDetails(record);
  };

  const handleOpenVideoImageModal = (row, isVideo) => {
    setIsVideo(isVideo);
    setSelectedRowDetails(row);
    setVideoImageModal(true);
  };
  const handleCloseVideoImageModal = () => {
    setVideoImageModal(false);
  };

  const handleSearchDiamond = (value) => {
    let filter = [];
    setSearchSku(value);
    handleGetLabDiamondList(
      dealerId,
      1,
      pageSize,
      filterVals.Shape,
      priceValue[0],
      priceValue[1],
      caratSlideValue[0],
      caratSlideValue[1],
      state.Color,
      state.Clarity,
      state.Cut,
      state.Polish,
      state.Symmetry,
      state.Fluorescence,
      filterVals.DepthMin,
      filterVals.DepthMax,
      filterVals.TableMin,
      filterVals.TableMax,
      filterVals.Certificates, // Certificate
      value, //Sku
      filterVals.Color, // color
      filterVals.Clarity, // clarity
      filterVals.Cut, // cut
      filterVals.Polish, // polish
      filterVals.Symmetry, //symmetry
      filterVals.Fluorescence, //fluorescence
      filter, // isFilter
      sortColumnName,
      sortType
    );
  };

  const handleFilterChangeCaratVal = (e) => {
    setCaratSlideValue(e);
  };

  const handleFilterChangePriceVal = (e) => {
    setPriceValue(e);
  };

  const handleValidation = () => {
    const { firstName, lastName, phoneNumber, emailAddress, comments } =
      diamondFormData;
    const validations = {
      firstNameVal: "",
      lastNameVal: "",
      phoneNumberVal: "",
      emailAddressVal: "",
      commentsVal: "",
    };
    let isValid = true;

    if (!firstName.trim()) {
      isValid = false;
      validations.firstNameVal = "First Name is Compulsory";
    }
    if (!lastName.trim()) {
      isValid = false;
      validations.lastNameVal = "Last Name is Compulsory";
    }

    if (!phoneNumber.trim()) {
      isValid = false;
      validations.phoneNumberVal = "Phone number is compulsory";
    } else if (
      (phoneNumber.length >= 10 && phoneNumber.includes("-")) ||
      (phoneNumber.length >= 10 && phoneNumber.includes(",")) ||
      (phoneNumber.length >= 10 && phoneNumber.includes("+"))
    ) {
      validations.phoneNumberVal = "";
    } else if (!/^(?!0{10,15})(\+\d{1,3}[-]?)?\d{10,15}$/.test(phoneNumber)) {
      isValid = false;
      validations.phoneNumberVal = "Phone number is invalid";
    } else {
      validations.phoneNumberVal = "";
    }

    let validRegex =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (!emailAddress.trim()) {
      isValid = false;
      validations.emailAddressVal = "Email address is compulsory";
    } else if (!emailAddress.match(validRegex)) {
      isValid = false;
      validations.emailAddressVal = "Email address is invalid";
    } else {
      validations.emailAddressVal = "";
    }
    if (!comments.trim()) {
      isValid = false;
      validations.commentsVal = "Comments is Compulsory";
    }

    if (!isValid) {
      setValidations(validations);
    }

    return isValid;
  };
  const handleCompareRedirect = () => {
    if (labDiamondList.filter((obj) => obj.checkbox == true).length == 0) {
      // NotificationManager.error("Kindly Select a diamond to Compare");
      handleOpenNoDiamondModal();
    } else {
      let compareIds = CompareIds.toString();
      navigate(
        "/Compare" +
          `?DealerLink=${paramdealerId}&CompareIds=${compareIds}&PageName=${"Lab"}`
      );
      dispatch(handleSetPage("Compare"));
    }
  };

  //Sorting

  const handleTableASCDES = (pagination, filters, sorter) => {
    let type = "";
    let column = "";
    let filter = [];
    let obj = {};

    //sorting
    if (sorter.order == "descend") {
      type = "DESC";
      column = sorter.field;
      setSortType(type);
      setSortColumnName(column);
    } else if (sorter.order == "ascend") {
      type = "ASC";
      column = sorter.field;
      setSortType(type);
      setSortColumnName(column);
    } else {
      type = "ASC";
      column = "dealerCompany";
      setSortColumnName(column);
      setSortType(type);
    }
    handleGetLabDiamondList(
      dealerId,
      pageNo,
      pageSize,
      filterVals.Shape,
      priceValue[0],
      priceValue[1],
      caratSlideValue[0],
      caratSlideValue[1],
      state.Color,
      state.Clarity,
      state.Cut,
      state.Polish,
      state.Symmetry,
      state.Fluorescence,
      filterVals.DepthMin,
      filterVals.DepthMax,
      filterVals.TableMin,
      filterVals.TableMax,
      filterVals.Certificates, // Certificate
      searchSku, //Sku
      filterVals.Color, // color
      filterVals.Clarity, // clarity
      filterVals.Cut, // cut
      filterVals.Polish, // polish
      filterVals.Symmetry, //symmetry
      filterVals.Fluorescence, //fluorescence
      filter,
      column,
      type
    );
  };

  const handleAfterChange = (e) => {
    handleGetLabDiamondList(
      dealerId,
      1,
      pageSize,
      filterVals.Shape,
      priceValue[0],
      priceValue[1],
      e[0],
      e[1],
      state.Color,
      state.Clarity,
      state.Cut,
      state.Polish,
      state.Symmetry,
      state.Fluorescence,
      filterVals.DepthMin,
      filterVals.DepthMax,
      filterVals.TableMin,
      filterVals.TableMax,
      filterVals.Certificates, // Certificate
      searchSku, //Sku
      filterVals.Color, // color
      filterVals.Clarity, // clarity
      filterVals.Cut, // cut
      filterVals.Polish, // polish
      filterVals.Symmetry, //symmetry
      filterVals.Fluorescence, //fluorescence
      true, //isFilter
      sortColumnName,
      sortType
    );
    let tempArray = filterVals;
    tempArray["CaratMin"] = e[0];
    tempArray["CaratMax"] = e[1];
    setFilterVals(tempArray);
    dispatch(handleSetSaveEveryTimeLabTab(tempArray));
  };

  const handleAfterChangePrice = (e) => {
    handleGetLabDiamondList(
      dealerId,
      1,
      pageSize,
      filterVals.Shape,
      e[0],
      e[1],
      caratSlideValue[0],
      caratSlideValue[1],
      state.Color,
      state.Clarity,
      state.Cut,
      state.Polish,
      state.Symmetry,
      state.Fluorescence,
      filterVals.DepthMin,
      filterVals.DepthMax,
      filterVals.TableMin,
      filterVals.TableMax,
      filterVals.Certificates, // Certificate
      searchSku, //Sku
      filterVals.Color, // color
      filterVals.Clarity, // clarity
      filterVals.Cut, // cut
      filterVals.Polish, // polish
      filterVals.Symmetry, //symmetry
      filterVals.Fluorescence, //fluorescence
      true, //isFilter
      sortColumnName,
      sortType
    );
    let tempArray = filterVals;
    tempArray["PriceMin"] = e[0];
    tempArray["PriceMax"] = e[1];
    setFilterVals(tempArray);
    dispatch(handleSetSaveEveryTimeLabTab(tempArray));
  };

  /* Color Individually Start */

  const [isLightHeaderColor, setIsLightHeaderColor] = useState(false);
  const [isLightButtonColor, setIsLightButtonColor] = useState(false);
  const [isLightHoverColor, setIsLightHoverColor] = useState(false);
  const [wrapperLoading, setWrapperLoading] = useState(false);

  const previewcolor = useSelector((state) => state.previewColorReducer);
  const [varColorObj, setVarColorObj] = useState({
    callToActionColor: "",
    hoverColor: "",
    columnHeaderAccentColor: "",
    linkColor: "",
  });
  const [toggle, setToggle] = useState(true);

  useLayoutEffect(() => {
    if (paramdealerId) {
      handleGetColorsData(paramdealerId);
    } else {
      if (loginDetails) {
        if (loginDetails.dealerId) {
          handleGetColorsData(loginDetails.dealerId);
        }
      }
    }
  }, [previewcolor.toogle]);

  const handleGetColorsData = async (id) => {
    let inputData = {
      dealerID: Number(id),
      moduleName: "PendantBuilder",
      hoverEffect: "",
      columnHeaderAccent: "",
      linkColor: "",
      actionButton: "",
    };
    setWrapperLoading(true);
    try {
      await colorPanelService
        .GetHoverEffect(inputData)
        .then((response) => {
          let msg = response.data.message;
          let initialColorObj =
            response.data.responseData.initialHoverEffectList[0];
          let newColorObj =
            response.data.responseData.ringBuilder_HoverEffect_List[0];
          let colorObj = {
            hoverEffect: "",
            columnHeaderAccent: "",
            linkColor: "",
            actionButton: "",
          };

          if (msg == "Success") {
            if (initialColorObj) {
              let tempobj = colorObj;
              tempobj.hoverEffect = newColorObj
                ? newColorObj.hoverEffect == ""
                  ? initialColorObj.hoverEffect
                  : newColorObj.hoverEffect
                : initialColorObj.hoverEffect;
              tempobj.columnHeaderAccent = newColorObj
                ? newColorObj.columnHeaderAccent == ""
                  ? initialColorObj.columnHeaderAccent
                  : newColorObj.columnHeaderAccent
                : initialColorObj.columnHeaderAccent;
              tempobj.linkColor = newColorObj
                ? newColorObj.linkColor == ""
                  ? initialColorObj.linkColor
                  : newColorObj.linkColor
                : initialColorObj.linkColor;
              tempobj.actionButton = newColorObj
                ? newColorObj.actionButton == ""
                  ? initialColorObj.actionButton
                  : newColorObj.actionButton
                : initialColorObj.actionButton;
              if (tempobj) {
                setVarColorObj({
                  ...varColorObj,
                  callToActionColor: tempobj.actionButton,
                  hoverColor: tempobj.hoverEffect,
                  columnHeaderAccentColor: tempobj.columnHeaderAccent,
                  linkColor: tempobj.linkColor,
                });
                lightOrDark(tempobj.columnHeaderAccent, "header");
                lightOrDark(tempobj.callToActionColor, "button");
                lightOrDark(tempobj.hoverColor, "hover");
                setStyles(tempobj);
              } else {
                setVarColorObj({
                  ...varColorObj,
                  callToActionColor: initialColorObj.actionButton,
                  hoverColor: initialColorObj.hoverEffect,
                  columnHeaderAccentColor: initialColorObj.columnHeaderAccent,
                  linkColor: initialColorObj.linkColor,
                });
                lightOrDark(initialColorObj.columnHeaderAccent, "header");
                lightOrDark(initialColorObj.callToActionColor, "button");
                lightOrDark(initialColorObj.hoverColor, "hover");
                setStyles(tempobj);
              }
            }
          }
          setWrapperLoading(false);
        })
        .catch((error) => {
          console.log(error);
          setWrapperLoading(false);
        });
    } catch (error) {
      console.log(error);
      setWrapperLoading(false);
    }
  };

  function lightOrDark(color, type) {
    // Variables for red, green, blue values
    let r, g, b, hsp;

    if (color) {
      // Check the format of the color, HEX or RGB?
      if (color.match(/^rgb/)) {
        // If RGB --> store the red, green, blue values in separate variables
        color = color.match(
          /^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/
        );

        r = color[1];
        g = color[2];
        b = color[3];
      } else {
        // If hex --> Convert it to RGB: http://gist.github.com/983661
        color = +(
          "0x" + color.slice(1).replace(color.length < 5 && /./g, "$&$&")
        );

        r = color >> 16;
        g = (color >> 8) & 255;
        b = color & 255;
      }

      // HSP (Highly Sensitive Poo) equation from http://alienryderflex.com/hsp.html
      hsp = Math.sqrt(0.299 * (r * r) + 0.587 * (g * g) + 0.114 * (b * b));

      // Using the HSP value, determine whether the color is light or dark
      // if hsp> 127.5 then it is dark
      if (hsp > 127.5) {
        if (type == "header") {
          setIsLightHeaderColor(true);
          setColorStyles(true);
        } else if (type == "button") {
          setIsLightButtonColor(true);
        } else {
          setIsLightHoverColor(true);
        }
      } else {
        if (type == "header") {
          setIsLightHeaderColor(false);
          setColorStyles(false);
        } else if (type == "button") {
          setIsLightButtonColor(false);
        } else {
          setIsLightHoverColor(false);
        }
      }
    }
  }

  function setStyles(obj) {
    document.documentElement.style.setProperty(
      "--background--table--color",
      obj.columnHeaderAccent
    );
  }

  function setColorStyles(val) {
    document.documentElement.style.setProperty(
      "--link--table--color",
      val ? "#3b4452" : "#ffffff"
    );
  }

  const Wrapper = styled.div`
    & .whole__shapes .shapes__block .image:hover {
      border: 1px solid ${varColorObj.hoverColor};
    }

    & .whole__shapes .shapes__block:hover .image {
      border: 1px solid ${varColorObj.hoverColor};
    }

    & .whole__shapes .shapes__block .image .selected {
      border: 1px solid ${varColorObj.linkColor};
    }

    & .whole__shapes .shapes__block.selected .image {
      border: 1px solid ${varColorObj.linkColor};
    }

    & .img__block .imgselct__div:hover {
      border-bottom: 2px solid ${varColorObj.hoverColor};
    }

    & .img__block .imgselct__div.selected {
      border-bottom: 2px solid ${varColorObj.linkColor};
    }

    & .selection__block .selection__box:hover {
      border: 1px solid ${varColorObj.hoverColor};
      background-color: ${varColorObj.hoverColor};
    }
    & .selection__block .selection__box.selected {
      border: 1px solid ${varColorObj.linkColor};
      background-color: ${varColorObj.linkColor};
    }

    & .ant-table-thead > tr > th {
      background: ${varColorObj.columnHeaderAccentColor} !important;
      font-family: "LexendDeca-Semibold";
      height: 50px;
      color: ${isLightHeaderColor ? "#3b4452" : "#ffffff"} !important;
      line-height: 14px;
    }

    & .ant-btn-primary {
      background: ${varColorObj.callToActionColor} !important;
      border-color: ${varColorObj.callToActionColor} !important;
    }
    & .ant-btn-primary:hover {
      background: ${varColorObj.hoverColor} !important;
      border-color: ${varColorObj.hoverColor} !important;
    }

    & .primary-btn {
      background: ${varColorObj.callToActionColor} !important;
      border-radius: 5px !important;
      color: ${isLightButtonColor ? "#3b4452" : "#ffffff"} !important;
      border: none;
      padding: 0px 10px;
      height: 35px;
    }

    & .primary-btn:hover {
      background: ${varColorObj.hoverColor} !important;
      border-radius: 5px !important;
      color: ${isLightHoverColor ? "#3b4452" : "#ffffff"} !important;
      border: none;
      padding: 0px 10px;
      height: 35px;
    }

    & .ant-btn {
      background: ${varColorObj.callToActionColor} !important;
      border-radius: 5px !important;
      color: ${isLightButtonColor ? "#3b4452" : "#fff"} !important;
      border: none;
      padding: 0px 10px;
      height: 35px;
    }

    & .ant-btn:hover {
      background: ${varColorObj.hoverColor} !important;
      border-radius: 5px !important;
      color: ${isLightHoverColor ? "#3b4452" : "#ffffff"} !important;
      border: none;
      padding: 0px 10px;
      height: 35px;
    }
    & .ant-input-search-button {
      background: #fff !important;
      border: 1px solid #d9d9d9;
      border-radius: 0 !important;
      height: 32px;
      border-left: 0;
    }

    & .ant-input-search-button:hover {
      background: #fff !important;
      border: 1px solid #d9d9d9;
      border-radius: 0 !important;
      height: 32px;
      border-left: 0;
    }
    & .linkText {
      color: ${varColorObj.linkColor};
      text-decoration: none;
      cursor: pointer;
    }

    & .linkText:hover {
      color: ${varColorObj.hoverColor};
    }

    & .iframe__block .itemdetails__btns p:hover img {
      filter: invert(64%) sepia(29%) saturate(4028%) hue-rotate(164deg)
        brightness(93%) contrast(89%);
      color: ${varColorObj.hoverColor};
    }

    & .iframe__block .itemdetails__btns p:hover span {
      color: ${varColorObj.hoverColor};
    }

    & .ant-pagination-item-active {
      border: 1px solid ${varColorObj.linkColor} !important;
    }

    & .ant-pagination-item:hover {
      border: 1px solid ${varColorObj.hoverColor};
    }
    & .nav-tabs .nav-link.active {
      background: ${varColorObj.columnHeaderAccentColor} !important;
      color: ${isLightHeaderColor ? "#3b4452" : "#ffffff"} !important;
    }

    & .list_icon.active {
      border: 1px solid ${varColorObj.callToActionColor};
      background: ${varColorObj.callToActionColor} !important;
      color: ${isLightButtonColor ? "#3b4452" : "#ffffff"} !important;
    }

    & .grid_icon.active {
      border: 1px solid ${varColorObj.callToActionColor};
      background: ${varColorObj.callToActionColor} !important;
      color: ${isLightButtonColor ? "#3b4452" : "#ffffff"} !important;
    }

    & .grid_icon:hover {
      border: 1px solid ${varColorObj.hoverColor};
      background: ${varColorObj.hoverColor} !important;
      color: ${isLightHoverColor ? "#3b4452" : "#ffffff"} !important;
    }

    & .list_icon:hover {
      border: 1px solid ${varColorObj.hoverColor};
      background: ${varColorObj.hoverColor} !important;
      color: ${isLightHoverColor ? "#3b4452" : "#ffffff"} !important;
    }

    & .ant-input-search-button {
      color: rgba(0, 0, 0, 0.45) !important;
    }
    & .ant-input-search-button:hover {
      color: rgba(0, 0, 0, 0.45) !important;
    }
  `;

  /* Color Individually Ends */

  const handleAddToCartView = () => {
    let domainUrl = viewCartButtonUrl.match(
      /^(?:https?:\/\/)?(?:[^@\n]+@)?(?:www\.)?([^:/\n?]+)/i
    );
    if (domainUrl && domainUrl.length > 1) {
      if (viewCartButtonUrl.includes("https")) {
        window.open(viewCartButtonUrl, "_blank");
      } else if (viewCartButtonUrl.includes("http")) {
        window.open(viewCartButtonUrl, "_blank");
      } else {
        window.open("https://" + viewCartButtonUrl, "_blank");
      }
    } else {
      NotificationManager.error("Please Add Shopping Cart Url");
    }
  };

  const handleOpenNoDiamondModal = () => {
    setNoDiamondToCompare(true);
  };
  const handleCloseNoDiamondModal = () => {
    setNoDiamondToCompare(false);
  };

  return (
    <Spin spinning={showAllContent} style={{ minHeight: "50vh" }}>
      {showAllContent == false && (
        <React.Fragment>
          <div className="allprod_section jwlsrch__section app__preview choose__setting">
            <div className="tabs__content">
              <div className="diamond__details">
                <div className="row border__block mb-0">
                  <div className="col-md-9">
                    <div className="row">
                      <Spin spinning={filterloading}>
                        <div className="form__fields filter_inpsection border-0 p-0 mt-3 mh-auto">
                          <div className="col-md-12">
                            <div className="row">
                              <Wrapper>
                                <div>
                                  <div className="col-lg-3 col-md-6">
                                    <div className="input__block mb-1">
                                      <div className="input__container">
                                        <label>Shape</label>
                                        <Tooltip
                                          placement="rightTop"
                                          title={`A diamond’s shape is not the same as a diamond’s cut. The shape refers to the general outline of the stone, and not its light refractive qualities. Look for a shape that best suits the ring setting you have chosen, as well as the recipient’s preference and personality.`}
                                        >
                                          <img src={UnionIcon} alt="" className="input__icon"  />
                                        </Tooltip>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="whole__shapes col-lg-12">
                                    {state.Shape &&
                                      state.Shape.map((item, i) => {
                                        return (
                                          <Fragment>
                                            <div
                                              className={
                                                filterVals.Shape &&
                                                filterVals.Shape.includes(
                                                  item.value
                                                )
                                                  ? "shapes__block selected"
                                                  : "shapes__block"
                                              }
                                              onClick={() =>
                                                handleLabGrownFilterChange(
                                                  item.value,
                                                  "Shape",
                                                  "filter"
                                                )
                                              }
                                            >
                                              <div className="image">
                                                <img src={item.shapeImage} />
                                              </div>
                                              <p>{item.name}</p>
                                            </div>
                                          </Fragment>
                                        );
                                      })}
                                  </div>
                                  <div className="col-md-12">
                                    <div className="input__block mb-1">
                                      <div className="input__container">
                                        <label>Cut</label>
                                        <Tooltip
                                          placement="rightTop"
                                          title={`Not to be confused with shape, a diamond’ s cut rating tells you how well its proportions interact with light.By evaluating the angles and proportions of the diamond, the cut grade is designed to tell you how sparkly and brilliant your stone is.Cut grading is usually not available for fancy shapes(any shape that is not round), because the mathematical formula that determines light return becomes less reliable when different length to width ratios are factored in .`}
                                        >
                                          <img src={UnionIcon} alt="" className="input__icon"/>
                                        </Tooltip>
                                      </div>
                                    </div>
                                    <div className="selection__block row">
                                      {state.Cut &&
                                        state.Cut.map((item, i) => {
                                          return (
                                            <Fragment>
                                              <div className="col">
                                                <div
                                                  className={
                                                    filterVals.Cut &&
                                                    filterVals.Cut.includes(
                                                      item.value
                                                    )
                                                      ? "selection__box selected"
                                                      : "selection__box"
                                                  }
                                                  onClick={() =>
                                                    handleLabGrownFilterChange(
                                                      item.value,
                                                      "Cut",
                                                      "filter"
                                                    )
                                                  }
                                                ></div>
                                                <p>{item.name}</p>
                                              </div>
                                            </Fragment>
                                          );
                                        })}
                                    </div>
                                  </div>
                                  <div className="col-md-12">
                                    <div className="input__block mb-1">
                                      <div className="input__container">
                                        <label>Color</label>
                                        <Tooltip
                                          placement="rightTop"
                                          title={`The color scale measures the degree of colorlessness in a diamond. D is the highest and most colorless grade, but also the most expensive. To get the most value for your budget, look for an eye colorless stone.`}
                                        >
                                          <img src={UnionIcon} alt="" className="input__icon" />
                                        </Tooltip>
                                      </div>
                                    </div>
                                    <div className="selection__block row">
                                      {state.Color &&
                                        state.Color.map((item, i) => {
                                          return (
                                            <Fragment>
                                              <div className="col">
                                                <div
                                                  className={
                                                    filterVals.Color &&
                                                    filterVals.Color.includes(
                                                      item.value
                                                    )
                                                      ? "selection__box selected"
                                                      : "selection__box"
                                                  }
                                                  onClick={() =>
                                                    handleLabGrownFilterChange(
                                                      item.value,
                                                      "Color",
                                                      "filter"
                                                    )
                                                  }
                                                ></div>
                                                <p>{item.name}</p>
                                              </div>
                                            </Fragment>
                                          );
                                        })}
                                    </div>
                                  </div>
                                  <div className="col-md-12">
                                    <div className="input__block mb-1">
                                      <div className="input__container">
                                        <label>Clarity</label>
                                        <Tooltip
                                          placement="rightTop"
                                          title={`A diamond’s clarity refers to the tiny traces of natural elements that are trapped inside the stone. 99% of diamonds contain inclusions or flaws. You do not need a flawless diamond - they are very rare and expensive - but you want to look for one that is perfect to the naked eye. Depending on the shape of the diamond, the sweet spot for clarity is usually between VVS2 to SI1.`}
                                        >
                                          <img src={UnionIcon} alt=""  className="input__icon" />
                                        </Tooltip>
                                      </div>
                                    </div>
                                    <div className="selection__block row">
                                      {state.Clarity &&
                                        state.Clarity.map((item, i) => {
                                          return (
                                            <Fragment>
                                              <div className="col">
                                                <div
                                                  className={
                                                    filterVals.Clarity &&
                                                    filterVals.Clarity.includes(
                                                      item.value
                                                    )
                                                      ? "selection__box selected"
                                                      : "selection__box"
                                                  }
                                                  onClick={() =>
                                                    handleLabGrownFilterChange(
                                                      item.value,
                                                      "Clarity",
                                                      "filter"
                                                    )
                                                  }
                                                ></div>
                                                <p>{item.name}</p>
                                              </div>
                                            </Fragment>
                                          );
                                        })}
                                    </div>
                                  </div>
                                </div>
                              </Wrapper>
                              <div className="col-md-6">
                                <div className="sliderrange__maindiv d-block">
                                  <div className="input__container">
                                    <label> {"Carats"} </label>
                                    <Tooltip
                                      placement="rightTop"
                                      title={`Carat is a unit of measurement to determine a diamond’s weight. Typically, a higher carat weight means a larger looking diamond, but that is not always the case. Look for the mm measurements of the diamond to determine its visible size.`}
                                    >
                                      <img src={UnionIcon} alt=""  style={{ cursor: 'pointer',marginTop:'2px' ,width:'14px'}} />
                                    </Tooltip>
                                  </div>
                                  <div className="col-md-12 mt-1">
                                    <Slider
                                      range
                                      value={caratSlideValue}
                                      min={state.CaratMin}
                                      max={state.CaratMax}
                                      step={0.01}
                                      onChange={(e) => {
                                        handleFilterChangeCaratVal(e);
                                      }}
                                      onAfterChange={(e) => {
                                        handleAfterChange(e);
                                      }}
                                    />
                                    <div className="rangeSlider__values">
                                      <div className="input__block">
                                        <input
                                          type="text"
                                          value={caratSlideValue[0].toLocaleString(
                                            "en-US"
                                          )}
                                          onChange={(e) => {
                                            firstsetCaratSlideValue(e, 0);
                                          }}
                                          onKeyPress={onKeyPressEvent1}
                                          onBlur={handleCaratsOnBlur}
                                        />
                                      </div>

                                      <div className="input__block">
                                        <input
                                          type="text"
                                          value={caratSlideValue[1].toLocaleString(
                                            "en-US"
                                          )}
                                          onChange={(e) => {
                                            firstsetCaratSlideValue(e, 1);
                                          }}
                                          onKeyPress={onKeyPressEvent1}
                                          onBlur={handleCaratsOnBlur}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="sliderrange__maindiv d-block">
                                  <div className="input__container">
                                    <label>Price Range</label>
                                    <Tooltip
                                      placement="rightTop"
                                      title={`This refers to different types of Price to filter and select the appropriate ring as per your requirements. Look for the best suit price of your chosen ring.`}
                                    >
                                      <img src={UnionIcon} alt=""  style={{ cursor: 'pointer' ,marginTop:'2px' ,width:'14px'}} />
                                    </Tooltip>
                                  </div>
                                  <div className="col-md-12 mt-1">
                                    <Slider
                                      range
                                      value={priceValue}
                                      min={state.PriceMin}
                                      max={state.PriceMax}
                                      onChange={(e) => {
                                        handleFilterChangePriceVal(e);
                                      }}
                                      onAfterChange={(e) => {
                                        handleAfterChangePrice(e);
                                      }}
                                    />
                                    <div className="rangeSlider__values">
                                      <div className="input__block">
                                        <input
                                          type="text"
                                          value={
                                            currencySymbol +
                                            priceValue[0]
                                              .toString()
                                              .replace(/[^0-9.]/g, "")
                                              .replace(
                                                /\B(?=(\d{3})+(?!\d))/g,
                                                ","
                                              )
                                          }
                                          onChange={(e) => {
                                            firstSliderPriceValue(e, 0);
                                          }}
                                          onBlur={handlePriceOnBlur}
                                        />
                                      </div>

                                      <div className="input__block">
                                        <input
                                          type="text"
                                          value={
                                            currencySymbol +
                                            priceValue[1]
                                              .toString()
                                              .replace(/[^0-9.]/g, "")
                                              .replace(
                                                /\B(?=(\d{3})+(?!\d))/g,
                                                ","
                                              )
                                          }
                                          onChange={(e) => {
                                            firstSliderPriceValue(e, 1);
                                          }}
                                          onKeyPress={onKeyPressEvent1}
                                          onBlur={handlePriceOnBlur}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div>
                                <Wrapper>
                                  {isAdvance ? (
                                    <div className="col-md-12 mt-2">
                                      <div className="input__block d-flex align-item-center">
                                        <button
                                          className="primary-btn mr-1"
                                          style={{
                                            width: "25px",
                                            height: "25px",
                                            padding: "0px",
                                          }}
                                          onClick={() =>
                                            setShowAdvanced(!showAdvanced)
                                          }
                                        >
                                          {showAdvanced ? "-" : "+"}
                                        </button>
                                        <label>Advanced</label>
                                      </div>
                                    </div>
                                  ) : null}
                                </Wrapper>

                                {showAdvanced == true ? (
                                  <div>
                                    <Wrapper>
                                      <div className="col-md-12">
                                        <div className="input__block mb-1">
                                          <div className="input__container">
                                            <label>Polish</label>
                                            <Tooltip
                                              placement="rightTop"
                                              title={`Polish describes how smooth the surface of a diamond is. Aim for an Excellent or Very Good polish rating.`}
                                            >
                                              <img src={UnionIcon} alt=""  className="input__icon" />
                                            </Tooltip>
                                          </div>
                                        </div>
                                        <div className="selection__block row">
                                          {state.Polish &&
                                            state.Polish.map((item, i) => {
                                              return (
                                                <Fragment>
                                                  <div className="col">
                                                    <div
                                                      className={
                                                        filterVals.Polish &&
                                                        filterVals.Polish.includes(
                                                          item.value
                                                        )
                                                          ? "selection__box selected"
                                                          : "selection__box"
                                                      }
                                                      onClick={() =>
                                                        handleLabGrownFilterChange(
                                                          item.value,
                                                          "Polish",
                                                          "filter"
                                                        )
                                                      }
                                                    ></div>
                                                    <p>{item.name}</p>
                                                  </div>
                                                </Fragment>
                                              );
                                            })}
                                        </div>
                                      </div>

                                      <div className="col-md-12">
                                        <div className="input__block mb-1">
                                          <div className="input__container">
                                            <label>Fluorescence</label>
                                            <Tooltip
                                              placement="rightTop"
                                              title={`Fluorescence tells you how a diamond responds to ultraviolet light - does it glow under a black light? Diamonds with no fluorescence are generally priced higher on the market, but it is rare for fluorescence to have any visual impact on the diamond; some fluorescence can even enhance the look of the stone. Our site recommends searching for diamonds with none to medium fluorescence, and keeping open the option of strong fluorescence for additional value.`}
                                            >
                                              <img src={UnionIcon} alt="" className="input__icon" />
                                            </Tooltip>
                                          </div>
                                        </div>
                                        <div className="selection__block row">
                                          {state.Fluorescence &&
                                            state.Fluorescence.map(
                                              (item, i) => {
                                                return (
                                                  <Fragment>
                                                    <div className="col">
                                                      <div
                                                        className={
                                                          filterVals.Fluorescence &&
                                                          filterVals.Fluorescence.includes(
                                                            item.value
                                                          )
                                                            ? "selection__box selected"
                                                            : "selection__box"
                                                        }
                                                        onClick={() =>
                                                          handleLabGrownFilterChange(
                                                            item.value,
                                                            "Fluorescence",
                                                            "filter"
                                                          )
                                                        }
                                                      ></div>
                                                      <p>{item.name}</p>
                                                    </div>
                                                  </Fragment>
                                                );
                                              }
                                            )}
                                        </div>
                                      </div>

                                      <div className="col-md-12">
                                        <div className="input__block mb-1">
                                          <div className="input__container">
                                            <label>Symmetry</label>
                                            <Tooltip
                                              placement="rightTop"
                                              title={`Symmetry describes how symmetrical the diamond is cut all the way around, which is a contributing factor to a diamond’s sparkle and brilliance. Aim for an Excellent or Very Good symmetry rating for round brilliant shapes, and Excellent to Good for fancy shapes.`}
                                            >
                                              <img src={UnionIcon} alt="" className="input__icon"/>
                                            </Tooltip>
                                          </div>
                                        </div>
                                        <div className="selection__block row">
                                          {state.Symmetry &&
                                            state.Symmetry.map((item, i) => {
                                              return (
                                                <Fragment>
                                                  <div className="col">
                                                    <div
                                                      className={
                                                        filterVals.Symmetry &&
                                                        filterVals.Symmetry.includes(
                                                          item.value
                                                        )
                                                          ? "selection__box selected"
                                                          : "selection__box"
                                                      }
                                                      onClick={() =>
                                                        handleLabGrownFilterChange(
                                                          item.value,
                                                          "Symmetry",
                                                          "filter"
                                                        )
                                                      }
                                                    ></div>
                                                    <p>{item.name}</p>
                                                  </div>
                                                </Fragment>
                                              );
                                            })}
                                        </div>
                                      </div>
                                    </Wrapper>
                                    <div className="row">
                                      <div className="col-md-4">
                                        <div className="input__block mb-1">
                                          <div className="input__container">
                                            <label>Depth</label>
                                            <Tooltip
                                              placement="rightTop"
                                              title={`Depth percentage is the height of the diamond measured from the culet to the table, divided by the width of the diamond. The lower the depth %, the larger the diamond will appear (given the same weight), but if this number is too low then the brilliance of the diamond will be sacrificed. The depth percentage is one of the elements that determines the Cut grading.`}
                                            >
                                              <img src={UnionIcon} alt=""  className="input__icon"/>
                                            </Tooltip>
                                          </div>
                                          <div className="row">
                                            <div className="col-md-6 mobile__bottomspace">
                                              <input
                                                type="number"
                                                value={filterVals.DepthMin}
                                                onKeyPress={
                                                  onKeyPressMinusEvent
                                                }
                                                onChange={(e) =>
                                                  handleLabGrownFilterChange(
                                                    e.target.value,
                                                    "DepthMin",
                                                    "input"
                                                  )
                                                }
                                                onPaste={preventPasteNegative}
                                                onBlur={(e) =>
                                                  handleOnBlur(
                                                    e.target.value,
                                                    "DepthMin",
                                                    "input"
                                                  )
                                                }
                                              />
                                            </div>
                                            <div className="col-md-6">
                                              <input
                                                type="number"
                                                value={filterVals.DepthMax}
                                                onKeyPress={
                                                  onKeyPressMinusEvent
                                                }
                                                onChange={(e) =>
                                                  handleLabGrownFilterChange(
                                                    e.target.value,
                                                    "DepthMax",
                                                    "input"
                                                  )
                                                }
                                                onPaste={preventPasteNegative}
                                                onBlur={(e) =>
                                                  handleOnBlur(
                                                    e.target.value,
                                                    "DepthMax",
                                                    "input"
                                                  )
                                                }
                                              />
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="col-md-4">
                                        <div className="input__block mb-1">
                                          <div className="input__container">
                                            <label>Table</label>
                                            <Tooltip
                                              placement="rightTop"
                                              title={`Table percentage is the width of a diamond’s largest facet the table divided by its overall width. It tells you how big the “face” of a diamond is.`}
                                            >
                                              <img src={UnionIcon} alt=""  className="input__icon" />
                                            </Tooltip>
                                          </div>
                                          <div className="row">
                                            <div className="col-md-6 mobile__bottomspace">
                                              <input
                                                type="number"
                                                value={filterVals.TableMin}
                                                onKeyPress={
                                                  onKeyPressMinusEvent
                                                }
                                                onChange={(e) =>
                                                  handleLabGrownFilterChange(
                                                    e.target.value,
                                                    "TableMin",
                                                    "input"
                                                  )
                                                }
                                                onPaste={preventPasteNegative}
                                                onBlur={(e) =>
                                                  handleOnBlur(
                                                    e.target.value,
                                                    "TableMin",
                                                    "input"
                                                  )
                                                }
                                              />
                                            </div>
                                            <div className="col-md-6">
                                              <input
                                                type="number"
                                                value={filterVals.TableMax}
                                                onKeyPress={
                                                  onKeyPressMinusEvent
                                                }
                                                onChange={(e) =>
                                                  handleLabGrownFilterChange(
                                                    e.target.value,
                                                    "TableMax",
                                                    "input"
                                                  )
                                                }
                                                onPaste={preventPasteNegative}
                                                onBlur={(e) =>
                                                  handleOnBlur(
                                                    e.target.value,
                                                    "TableMax",
                                                    "input"
                                                  )
                                                }
                                              />
                                            </div>
                                          </div>
                                        </div>
                                      </div>

                                      <div className="col-md-4">
                                        <div className="input__block mb-1">
                                          <label>Certificates</label>
                                          <Select
                                            className="border__grey"
                                            showSearch
                                            placeholder="Certificates"
                                            optionFilterProp="children"
                                            onChange={(e) =>
                                              handleLabGrownFilterChange(
                                                e,
                                                "Certificates",
                                                "input"
                                              )
                                            }
                                            filterOption={(input, option) =>
                                              option.children
                                                .toLowerCase()
                                                .indexOf(input.toLowerCase()) >=
                                              0
                                            }
                                            mode="multiple"
                                            showArrow
                                            value={
                                              selectedCertificates.includes(
                                                "all"
                                              )
                                                ? "all"
                                                : selectedCertificates
                                            }
                                          >
                                            <Option value="all">
                                              Show All Certificates
                                            </Option>
                                            {state.Certificates.map((cert) => {
                                              return (
                                                <Option
                                                  disabled={
                                                    selectedCertificates
                                                      ? selectedCertificates.includes(
                                                          "all"
                                                        )
                                                        ? true
                                                        : false
                                                      : false
                                                  }
                                                  value={cert.value}
                                                >
                                                  {cert.name}
                                                </Option>
                                              );
                                            })}
                                          </Select>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                ) : null}
                              </div>
                            </div>
                          </div>
                        </div>
                      </Spin>
                    </div>
                  </div>
                  <div className="col-md-3 diamondimage__block">
                    <Wrapper>
                      <div className="">
                        <Spin spinning={loading}>
                          <h3 className="subheading">Diamond Preview</h3>
                          {count == 1 ? (
                            <>
                              <img
                                className="img-fluid"
                                src={
                                  rowDetails.BigImageFileName
                                    ? rowDetails.BigImageFileName
                                    : rowDetails.ImageFileName
                                }
                              />
                              <h4 className="subheading">
                                {callForPrice ? (
                                  <span className="subheading">
                                    Call For Price
                                  </span>
                                ) : rowDetails.RealPrice == "0" ? (
                                  <>Call For Price</>
                                ) : (
                                  <span className="subheading">
                                    {rowDetails?.currencycode ?? ""}{" "}
                                    {currencySymbol}
                                    {parseInt(rowDetails.RealPrice)
                                      .toFixed()
                                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                  </span>
                                )}
                              </h4>
                              <p>
                                Stock Number:{" "}
                                {rowDetails.txtStockNo
                                  ? rowDetails.txtStockNo
                                  : null}
                              </p>

                              <div className="mb-3">
                                <button
                                  className="primary-btn mr-2"
                                  onClick={() =>
                                    handleViewDetails(rowDetails, "addSetting")
                                  }
                                >
                                  {" "}
                                  Add To Your Pendant
                                </button>
                              </div>

                              {viewCartButton == true ? (
                                <div className="mb-3">
                                  <button
                                    className="primary-btn mr-2"
                                    onClick={handleAddToCartView}
                                  >
                                    View Cart
                                  </button>
                                </div>
                              ) : null}

                              <div className="mb-3">
                                <button
                                  className="primary-btn mr-2"
                                  onClick={() => handleViewDetails(rowDetails)}
                                >
                                  {" "}
                                  View Details
                                </button>
                              </div>
                            </>
                          ) : (
                            <>
                              <img src={NoPreview} />
                              <p>No Results Found </p>

                              <p>Use The Form Below</p>
                            </>
                          )}
                        </Spin>
                      </div>
                    </Wrapper>
                  </div>
                </div>
              </div>

              {labDiamondList.length > 0 ? (
                <div className="col-md-12">
                  <div className="filter_section mt-4 p-0">
                    <Wrapper>
                      <div className="col-md-12 desktop__version">
                        <div className="filt_optndiv">
                          <div className="gview__div mt-4">
                            <span className="font__bold mr-2 similar__text">
                              {TotalRowCount} Similar Diamonds
                            </span>
                            <span
                              className={
                                viewItem === "grid"
                                  ? "fa fa-th grid_icon active"
                                  : "fa fa-th grid_icon"
                              }
                              onClick={() => handleViewChange("grid")}
                            ></span>
                            <span
                              className={
                                viewItem === "list"
                                  ? "fa fa-th-list list_icon active"
                                  : "fa fa-th-list list_icon"
                              }
                              onClick={() => handleViewChange("list")}
                            ></span>

                            <span className="font__bold ml-2">
                              Compare Items (
                              {
                                labDiamondList.filter(
                                  (obj) => obj.checkbox == true
                                ).length
                              }
                              )
                            </span>
                          </div>
                          <div className="mt-4">
                            <Search
                              placeholder="Search Diamond Stock #"
                              onSearch={(e) => handleSearchDiamond(e)}
                              allowClear
                              style={{ width: 250 }}
                            />
                          </div>
                        </div>
                      </div>
                    </Wrapper>
                    <Wrapper>
                      <div className="col-md-12 mt-3 mobile__version">
                        <div className="filt_optndiv">
                          <div className="gview__div mt-3">
                            <div className="row">
                              <div className="col-4">
                                <span className="font__bold mr-2 similar__text">
                                  {TotalRowCount} Similar Diamonds
                                </span>
                              </div>
                              <div className="col-4">
                                <span
                                  className={
                                    viewItem === "grid"
                                      ? "fa fa-th grid_icon active"
                                      : "fa fa-th grid_icon"
                                  }
                                  onClick={() => handleViewChange("grid")}
                                ></span>
                                <span
                                  className={
                                    viewItem === "list"
                                      ? "fa fa-th-list list_icon active"
                                      : "fa fa-th-list list_icon"
                                  }
                                  onClick={() => handleViewChange("list")}
                                ></span>
                              </div>
                              <div className="col-4">
                                <span className="font__bold">
                                  Compare Items (
                                  {
                                    labDiamondList.filter(
                                      (obj) => obj.checkbox == true
                                    ).length
                                  }
                                  )
                                </span>
                              </div>
                              <div className="col-12">
                                <div>
                                  <Search
                                    placeholder="Search Diamond Stock #"
                                    onSearch={(e) => handleSearchDiamond(e)}
                                    allowClear
                                    style={{ width: 250 }}
                                  />
                                </div>
                              </div>{" "}
                            </div>
                          </div>
                        </div>
                      </div>
                    </Wrapper>
                    <Wrapper>
                      {viewItem === "grid" && (
                        <Spin spinning={loading}>
                          <div className="col-md-12 mt-4">
                            <div className="prod__section">
                              <div className="row">
                                {labDiamondList.length > 0 ? (
                                  labDiamondList.map((item, i) => {
                                    return (
                                      <div className="col-lg-3 col-md-6 mb-3">
                                        <div className="prod__maindiv">
                                          <div className="display-block chkbx__div">
                                            <Checkbox
                                              name="checkbox"
                                              checkedChildren="Yes"
                                              unCheckedChildren="No"
                                              checked={
                                                item.checkbox == true
                                                  ? true
                                                  : false
                                              }
                                              onChange={(e) =>
                                                handleOnChange(
                                                  item,
                                                  e,
                                                  "checkbox"
                                                )
                                              }
                                            />
                                          </div>
                                          <div className="prodimg__div">
                                            <img
                                              src={
                                                item.BigImageFileName
                                                  ? item.BigImageFileName
                                                  : item.ImageFileName
                                              }
                                              onClick={() =>
                                                handleViewDetails(item)
                                              }
                                              alt=""
                                            />
                                          </div>
                                          <div className="details__div">
                                            <span className="style__txt">
                                              {"Style No"}: {item.txtStockNo}
                                            </span>
                                            {callForPrice ? (
                                              <span className="price__txt">
                                                Call For Price
                                              </span>
                                            ) : (
                                              <span className="price__txt">
                                                {item?.currencycode ?? ""}{" "}
                                                {currencySymbol}
                                                {parseInt(item.RealPrice)
                                                  .toFixed(2)
                                                  .replace(
                                                    /\B(?=(\d{3})+(?!\d))/g,
                                                    ","
                                                  )}
                                              </span>
                                            )}
                                          </div>
                                          <div className="display-block editdel__div">
                                            {item.VideoFileName ? (
                                              <div
                                                onClick={() => {
                                                  handleOpenVideoImageModal(
                                                    item,
                                                    true
                                                  );
                                                }}
                                              >
                                                <img src={Video_icon} alt="" />
                                              </div>
                                            ) : null}
                                          </div>
                                          {!productChange[item.intDiamondId] ? (
                                            <div
                                              className="info__div"
                                              onClick={() =>
                                                handleProductChangeOpen(
                                                  item.intDiamondId
                                                )
                                              }
                                            >
                                              <img src={Info_icon} alt="" />
                                            </div>
                                          ) : (
                                            <div className="descr__div">
                                              <div className="overlay__block">
                                                <p>
                                                  <span> {"Shape"} </span>
                                                  <span>:</span>
                                                  <span>{item.txtShape}</span>
                                                </p>
                                                <p>
                                                  <span> {"Carats"} </span>
                                                  <span>:</span>
                                                  <span>{item.fltCarat}</span>
                                                </p>
                                                <p>
                                                  <span> {"Color"} </span>
                                                  <span>:</span>
                                                  <span>{item.txtColor}</span>
                                                </p>
                                                <p>
                                                  <span> {"Clarity"} </span>
                                                  <span>:</span>
                                                  <span>
                                                    {" "}
                                                    {item.txtClarity}
                                                  </span>
                                                </p>
                                                <p>
                                                  <span> {"Cut"} </span>
                                                  <span>:</span>
                                                  <span>
                                                    {" "}
                                                    {item.txtCutGrade}
                                                  </span>
                                                </p>
                                                <p>
                                                  <span> {"In House"} </span>
                                                  <span>:</span>
                                                  <span> {item.inhouse}</span>
                                                </p>

                                                <p>
                                                  <span> {"Depth"} </span>
                                                  <span>:</span>
                                                  <span>{item.fltDepth}</span>
                                                </p>
                                                <p>
                                                  <span> {"Table"} </span>
                                                  <span>:</span>
                                                  <span>{item.fltTable}</span>
                                                </p>
                                                <p>
                                                  <span> {"Certificate"} </span>
                                                  <span>:</span>
                                                  <span>
                                                    {item.txtCertificate}
                                                  </span>
                                                </p>
                                                <p>
                                                  <span> {"Polish"} </span>
                                                  <span>:</span>
                                                  <span>{item.txtPolish}</span>
                                                </p>
                                                <p>
                                                  <span> {"Measurement"} </span>
                                                  <span>:</span>
                                                  <span>
                                                    {item.txtMeasurements}
                                                  </span>
                                                </p>
                                                <p>
                                                  <span>
                                                    {" "}
                                                    {"Retail Price"}{" "}
                                                  </span>
                                                  <span>:</span>
                                                  <span>
                                                    {callForPrice ? (
                                                      <span>
                                                        Call For Price
                                                      </span>
                                                    ) : (
                                                      <div>
                                                        {item.RealPrice ==
                                                        "0" ? (
                                                          <>
                                                            Call{" "}
                                                            <span className="hideInMobile">
                                                              For Price
                                                            </span>
                                                          </>
                                                        ) : (
                                                          <span>
                                                            {item?.currencycode ??
                                                              ""}{" "}
                                                            {currencySymbol}
                                                            {parseInt(
                                                              item.RealPrice
                                                            )
                                                              .toFixed()
                                                              .replace(
                                                                /\B(?=(\d{3})+(?!\d))/g,
                                                                ","
                                                              )}
                                                          </span>
                                                        )}
                                                      </div>
                                                    )}
                                                  </span>
                                                </p>
                                              </div>
                                              <div
                                                className="close__div"
                                                onClick={() =>
                                                  handleProductChangeClose(
                                                    item.intDiamondId
                                                  )
                                                }
                                              >
                                                <img src={Close} alt="" />
                                              </div>
                                            </div>
                                          )}
                                        </div>
                                      </div>
                                    );
                                  })
                                ) : (
                                  <span className="mr-2">
                                    No items found{" "}
                                    <span className="linkText">reset</span> your
                                    filters
                                  </span>
                                )}
                              </div>

                              <div className="row">
                                <div className="col-md-12">
                                  <div className="table__bottom__btn">
                                    {localStorage.getItem("compareTabData") &&
                                    JSON.parse(
                                      localStorage.getItem("compareTabData")
                                    ) &&
                                    JSON.parse(
                                      localStorage.getItem("compareTabData")
                                    ).setting == "False" ? null : (
                                      <div>
                                        <button
                                          className="primary-btn mr-2"
                                          onClick={() =>
                                            handleCompareRedirect()
                                          }
                                        >
                                          Add To Compare
                                        </button>
                                      </div>
                                    )}
                                    <div>
                                      <Pagination
                                        current={pageNo}
                                        pageSize={pageSize}
                                        total={TotalRowCount}
                                        onChange={onChange}
                                        onShowSizeChange={onShowSizeChange}
                                        showSizeChanger="true"
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Spin>
                      )}
                    </Wrapper>
                    {viewItem === "list" && (
                      <Spin spinning={loading}>
                        <div className="col-md-12 mt-4">
                          <div className="lviewprod__tbl">
                            <Table
                              className="info__tooltip__table"
                              columns={columns}
                              onChange={handleTableASCDES}
                              onRow={(record, recordIndex) => ({
                                onClick: (event) => {
                                  handleSetRowDetils(event, record);
                                },
                              })}
                              rowClassName={(record) =>
                                record.intDiamondId == rowDetails.intDiamondId
                                  ? "row-active"
                                  : ""
                              }
                              dataSource={labDiamondList}
                              scroll={{ y: 500 }}
                              pagination={false}
                            />
                          </div>
                          <div className="table__bottom__btn">
                            {localStorage.getItem("compareTabData") &&
                            JSON.parse(
                              localStorage.getItem("compareTabData")
                            ) &&
                            JSON.parse(localStorage.getItem("compareTabData"))
                              .setting == "False" ? null : (
                              <div>
                                <Button
                                  className="primary-btn mr-2"
                                  onClick={() => handleCompareRedirect()}
                                  style={{
                                    background: `${varColorObj.callToActionColor}`,
                                    color: `${
                                      isLightButtonColor ? "#3b4452" : "#ffffff"
                                    }`,
                                  }}
                                >
                                  Add To Compare
                                </Button>
                              </div>
                            )}
                            <div>
                              <Pagination
                                current={pageNo}
                                pageSize={pageSize}
                                total={TotalRowCount}
                                onChange={onChange}
                                onShowSizeChange={onShowSizeChange}
                                showSizeChanger="true"
                              />
                            </div>
                          </div>
                        </div>
                      </Spin>
                    )}
                  </div>
                </div>
              ) : (
                <div class="col-md-12">
                  <Spin spinning={loading}>
                    <div class="table__block filter_section mt-3">
                      <h5 className="subheading">
                        {" "}
                        No Diamonds Listed In That Range{" "}
                      </h5>
                      <p>
                        {" "}
                        Please enter your contact information. One of our
                        diamond specialists will contact you and assist you with
                        your needs.{" "}
                      </p>
                      <div class="row p-0 border-0 form__fields mh-auto">
                        <div class="col-md-6">
                          <div class="input__block">
                            <label> First Name </label>
                            <input
                              type="text"
                              placeholder="Enter First Name"
                              value={diamondFormData.firstName}
                              className={
                                validations.firstNameVal && "border__red"
                              }
                              onChange={(e) =>
                                handleInputDiamondDataChange(
                                  e,
                                  "firstName",
                                  "input"
                                )
                              }
                            />
                            <div>
                              {validations.firstNameVal && (
                                <p className="error-color-red">
                                  {validations.firstNameVal}
                                </p>
                              )}
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="input__block">
                            <label> Last Name </label>
                            <input
                              type="text"
                              placeholder="Enter Last Name"
                              value={diamondFormData.lastName}
                              className={
                                validations.lastNameVal && "border__red"
                              }
                              onChange={(e) =>
                                handleInputDiamondDataChange(
                                  e,
                                  "lastName",
                                  "input"
                                )
                              }
                            />
                            <div>
                              {validations.lastNameVal && (
                                <p className="error-color-red">
                                  {validations.lastNameVal}
                                </p>
                              )}
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="input__block">
                            <label> Phone Number </label>
                            <input
                              type="text"
                              placeholder="Enter Phone Number"
                              value={diamondFormData.phoneNumber}
                              className={
                                validations.phoneNumberVal && "border__red"
                              }
                              onChange={(e) =>
                                handleInputDiamondDataChange(
                                  e,
                                  "phoneNumber",
                                  "input"
                                )
                              }
                            />
                            <div>
                              {validations.phoneNumberVal && (
                                <p className="error-color-red">
                                  {validations.phoneNumberVal}
                                </p>
                              )}
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="input__block">
                            <label> Email Address </label>
                            <input
                              type="text"
                              placeholder="Enter Email Address"
                              value={diamondFormData.emailAddress}
                              className={
                                validations.emailAddressVal && "border__red"
                              }
                              onChange={(e) =>
                                handleInputDiamondDataChange(
                                  e,
                                  "emailAddress",
                                  "input"
                                )
                              }
                            />
                            <div>
                              {validations.emailAddressVal && (
                                <p className="error-color-red">
                                  {validations.emailAddressVal}
                                </p>
                              )}
                            </div>
                          </div>
                        </div>
                        <div class="col-md-12">
                          <div class="input__block">
                            <label> Comments </label>
                            <textarea
                              value={diamondFormData.comments}
                              className={
                                validations.commentsVal && "border__red"
                              }
                              onChange={(e) =>
                                handleInputDiamondDataChange(
                                  e,
                                  "comments",
                                  "input"
                                )
                              }
                            ></textarea>
                            <div>
                              {validations.commentsVal && (
                                <p className="error-color-red">
                                  {validations.commentsVal}
                                </p>
                              )}
                            </div>
                          </div>
                        </div>

                        <div class="col-md-12">
                          <div class="save__form">
                            <Button
                              type="primary"
                              className="mr-1"
                              class="primary-btn"
                              onClick={handleInputDiamondDataReset}
                              style={{
                                background: `${varColorObj.callToActionColor}`,
                                color: `${
                                  isLightButtonColor ? "#3b4452" : "#ffffff"
                                }`,
                              }}
                            >
                              {" "}
                              Reset{" "}
                            </Button>
                            <Button
                              loading={reqDiamondloading}
                              type="primary"
                              onClick={handleInputDiamondDataRequest}
                              style={{
                                background: `${varColorObj.callToActionColor}`,
                                color: `${
                                  isLightButtonColor ? "#3b4452" : "#ffffff"
                                }`,
                              }}
                            >
                              {" "}
                              Request{" "}
                            </Button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Spin>
                </div>
              )}
            </div>
          </div>

          <Modal
            className="modalconsupld__section"
            style={{ top: 20 }}
            title={"Video"}
            width={500}
            visible={videoImageModal}
            onCancel={() => handleCloseVideoImageModal()}
            footer={[
              <Button onClick={() => handleCloseVideoImageModal()}>
                {"Cancel"}
              </Button>,
            ]}
          >
            <div className="col-lg-12">
              <div className="modal__body">
                <div className="col-lg-12">
                  <div className="form__fields border-0 p-0">
                    <div className="row">
                      {isVideo ? (
                        selectedRowDetails.videoFileName ? (
                          <iframe
                            width="100%"
                            height="450"
                            title="diamond-video"
                            className="VideoIframe"
                            src={selectedRowDetails.videoFileName}
                          ></iframe>
                        ) : (
                          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                        )
                      ) : selectedRowDetails.imageFileName ? (
                        <img
                          src={selectedRowDetails.imageFileName}
                          alt="img-diamond"
                        />
                      ) : (
                        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Modal>

          <Modal
            title="Request Certificate"
            width={700}
            visible={requestCertModal}
            style={{ top: 20 }}
            className="request_info"
            onCancel={closeCertInfoModal}
            footer={[
              <Button
                key="back"
                style={{
                  background: `${varColorObj.callToActionColor}`,
                  color: `${isLightButtonColor ? "#3b4452" : "#ffffff"}`,
                }}
                onClick={closeCertInfoModal}
              >
                Cancel
              </Button>,
              <Button
                key="submit"
                style={{
                  background: `${varColorObj.callToActionColor}`,
                  color: `${isLightButtonColor ? "#3b4452" : "#ffffff"}`,
                }}
                loading={requestCertModalLoading}
                type="primary"
                onClick={handleSubmitRequestCert}
              >
                Request
              </Button>,
            ]}
          >
            <div className="row">
              <div class="col-md-12">
                <div className="table__block">
                  <div className="row p-0 border-0 form__fields mh-auto">
                    <div className="col-md-6">
                      <div className="input__block">
                        <label>
                          Name <span className="mandatory">*</span>{" "}
                        </label>
                        <input
                          type="text"
                          value={certInfoData.name}
                          className={
                            certInfoValidation.nameVal && "border__red"
                          }
                          onChange={(e) =>
                            handleCertInfoChange(e, "name", "input")
                          }
                        />
                        {certInfoValidation.nameVal && (
                          <p className="error-color-red">
                            {certInfoValidation.nameVal}
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="input__block">
                        <label>
                          E-Mail Address <span className="mandatory">*</span>{" "}
                        </label>
                        <input
                          type="text"
                          value={certInfoData.email}
                          className={
                            certInfoValidation.emailVal && "border__red"
                          }
                          onChange={(e) =>
                            handleCertInfoChange(e, "email", "input")
                          }
                        />
                        {certInfoValidation.emailVal && (
                          <p className="error-color-red">
                            {certInfoValidation.emailVal}
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="input__block">
                        <label>
                          Phone Number <span className="mandatory">*</span>{" "}
                        </label>
                        <input
                          type="text"
                          value={certInfoData.phoneNumber}
                          className={
                            certInfoValidation.phoneNumberVal && "border__red"
                          }
                          onChange={(e) =>
                            handleCertInfoChange(e, "phoneNumber", "input")
                          }
                        />
                        {certInfoValidation.phoneNumberVal && (
                          <p className="error-color-red">
                            {certInfoValidation.phoneNumberVal}
                          </p>
                        )}
                      </div>
                    </div>

                    <div className="col-md-12">
                      <div className="input__block">
                        <label>Comments</label>
                        <textarea
                          value={certInfoData.comments}
                          onChange={(e) =>
                            handleCertInfoChange(e, "comments", "input")
                          }
                        ></textarea>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Modal>
          <Modal
            title="Alert"
            className="datamapping__modal"
            width={400}
            centered
            visible={noDiamondToCompare}
            onCancel={handleCloseNoDiamondModal}
            maskClosable={false}
            okButtonProps={{ style: { display: "none" } }}
          >
            <div className="form__fields row border-0 p-0">
              <div className="col-lg-12 text-center">
                <p> Kindly Select a diamond to Compare </p>
              </div>
            </div>
          </Modal>
          <Modal
            title={reqModalDataForApiRes?.success ? "Success" : "Warning"}
            className="datamapping__modal"
            width={400}
            centered
            visible={reqModalDataForApiRes.show}
            onCancel={() =>
              setReqModalDataForApiRes(initialReqModalDataForApiRes)
            }
            maskClosable={false}
            okButtonProps={{ style: { display: "none" } }}
          >
            <div className="form__fields row border-0 p-0">
              <div className="col-lg-12 text-center">
                <p> {reqModalDataForApiRes?.msg ?? ""} </p>
              </div>
            </div>
          </Modal>
        </React.Fragment>
      )}
    </Spin>
  );
};

export default LabGrown;
