import React, { useState, useEffect, Fragment, useLayoutEffect } from "react";
import { NotificationManager } from "react-notifications";
import { Select, Slider,Tooltip, Spin, Modal, Button, Empty } from "antd";
import { useDispatch, useSelector } from "react-redux";
import SelectYourDiamondService from "../../../services/select-your-diamond.service";
import colorPanelService from "../../../services/color-panel.service";
import styled from "styled-components";
import { useLocation } from "react-router-dom";
import UnionIcon from "../../../assets/images/icons/Union_5.svg";
import {
  handleSetSaveEveryTimeRequestTab,
  handleSetSaveFilterRequestTab,
} from "../../../actions/selectYourDiamond/selectYourDiamond";

const initialDiamondFormInputData = {
  firstName: "",
  lastName: "",
  phoneNumber: "",
  emailAddress: "",
  comments: "",
};

const initialDiamondFormInputDataVal = {
  firstNameVal: "",
  lastNameVal: "",
  phoneNumberVal: "",
  emailAddressVal: "",
  commentsVal: "",
};

const initialReqModalDataForApiRes = {
  show: false,
  success: true,
  msg: "",
};

const Request = () => {
  const search = useLocation().search;
  const paramdealerId = new URLSearchParams(search).get("DealerLink");
  const resetVal = useSelector((state) => state.selectYourDiamondReducer);
  const loginDetails = useSelector((state) => state.loginDetailsReducer);
  const [priceValue, setPriceValue] = useState([0, 1000000]);
  const [caratSlideValue, setCaratSlideValue] = useState([0, 22]);
  const [filterloading, setFilterLoading] = useState(false);
  const [reqDiamondloading, setReqDiamondLoading] = useState(false);
  const [isVideo, setIsVideo] = useState(false);
  const [videoImageModal, setVideoImageModal] = useState(false);
  const [selectedRowDetails, setSelectedRowDetails] = useState({});
  const dispatch = useDispatch();

  const [state, setState] = useState({
    productChange: {},
    PriceMin: 0,
    PriceMax: 1000000,
    CaratMin: 0,
    CaratMax: 22,
    Shape: [],
    Cut: [],
    Color: [],
    Clarity: [],
    Polish: [],
    Fluorescence: [],
    Symmetry: [],
    TableMin: 0,
    TableMax: 100,
    DepthMin: 0,
    DepthMax: 100,
    Certificates: [],
  });
  const [showAdvanced, setShowAdvanced] = useState(false);
  const [diamondFormData, setDiamondFormData] = useState(
    initialDiamondFormInputData
  );
  const [pageNo, setPageNo] = useState(1);
  const [currencySymbol, setCurrencySymbol] = useState("");
  const [pageSize, setPageSize] = useState(10);
  const [dealerId, setDealerId] = useState();
  const [filterVals, setFilterVals] = useState({
    Shape: [],
    Cut: [],
    Color: [],
    Clarity: [],
    Polish: [],
    Fluorescence: [],
    Symmetry: [],
    TableMin: 0,
    TableMax: 100,
    DepthMin: 0,
    DepthMax: 100,
    PriceMin: 0,
    PriceMax: 1000000,
    CaratMin: 0,
    CaratMax: 22,
    Certificates: [],
  });

  const [validations, setValidations] = useState(
    initialDiamondFormInputDataVal
  );
  const [isAdvance, setIsAdvance] = useState(true);
  /* Color Individually Start */

  const [isLightHeaderColor, setIsLightHeaderColor] = useState(false);
  const [isLightButtonColor, setIsLightButtonColor] = useState(false);
  const [isLightHoverColor, setIsLightHoverColor] = useState(false);
  const previewcolor = useSelector((state) => state.previewColorReducer);
  const [varColorObj, setVarColorObj] = useState({
    callToActionColor: "",
    hoverColor: "",
    columnHeaderAccentColor: "",
    linkColor: "",
  });
  const [showAllContent, setShowAllContent] = useState(true);
  const [reqModalDataForApiRes, setReqModalDataForApiRes] = useState(
    initialReqModalDataForApiRes
  );

  useEffect(() => {
    if (paramdealerId) {
      handleGetDiamondFilters(
        paramdealerId,
        pageNo,
        pageSize,
        resetVal.saveSearchRequest
      );
      setDealerId(paramdealerId);
    } else {
      if (loginDetails) {
        if (loginDetails.dealerId) {
          handleGetDiamondFilters(
            loginDetails.dealerId,
            pageNo,
            pageSize,
            resetVal.saveSearchRequest
          );
          setDealerId(loginDetails.dealerId);
        }
      }
    }
  }, [resetVal.saveSearchRequest]);

  const { Option } = Select;

  const onKeyPressEvent1 = (event) => {
    const keyCode = event.keyCode || event.which;
    const keyValue = String.fromCharCode(keyCode);
    if (!new RegExp("[0-9.]").test(keyValue)) event.preventDefault();
    return;
  };

  const firstSliderPriceValue = (e, num) => {
    let newArr = [...priceValue];
    let StrValue = e.target.value && e.target.value.replace(currencySymbol, "");
    let Value = StrValue.replace(/,/g, "");

    if (Number(parseFloat(Value.replace(/,/g, ""))) > filterVals.PriceMax) {
      e.preventDefault();
    } else if (Number(num) == 0) {
      if (Value == "") {
        newArr[0] = 0;
        setPriceValue(newArr);
        // let tempArray = filterVals;
        // tempArray["PriceMin"] = 0;
        // setFilterVals(tempArray)
        // dispatch(handleSetSaveEveryTimeRequestTab(tempArray));
      } else {
        if (newArr[0] == 0 && Value.includes(".")) {
          newArr[0] = Value;
          setPriceValue(newArr);
        } else {
          if (newArr[0] == 0) {
            newArr[0] = parseFloat(Value);
            setPriceValue(newArr);
          } else {
            newArr[0] = Value;
            setPriceValue(newArr);
          }
        }
        // let tempArray = filterVals;
        // tempArray["PriceMin"] = Value;
        // setFilterVals(tempArray)
        // dispatch(handleSetSaveEveryTimeRequestTab(tempArray));
      }
    } else if (Number(num) == 1) {
      if (Value == "") {
        newArr[1] = 0;
        setPriceValue(newArr);
        // let tempArray = filterVals;
        // tempArray["PriceMax"] = 0;
        // setFilterVals(tempArray)
        // dispatch(handleSetSaveEveryTimeRequestTab(tempArray));
      } else {
        if (newArr[1] == 0 && Value.includes(".")) {
          newArr[1] = Value;
          setPriceValue(newArr);
        } else {
          if (newArr[1] == 0) {
            newArr[1] = parseFloat(Value);
            setPriceValue(newArr);
          } else {
            newArr[1] = Value;
            setPriceValue(newArr);
          }
        }
        // let tempArray = filterVals;
        // tempArray["PriceMax"] = Value;
        // setFilterVals(tempArray)
        // dispatch(handleSetSaveEveryTimeRequestTab(tempArray));
      }
    }
  };

  const firstsetCaratSlideValue = (e, num) => {
    let newArr = [...caratSlideValue];
    let StrValue = e.target.value && e.target.value.replace("$", "");
    let Value = StrValue.replace(/,/g, "");
    if (
      Number(parseFloat(e.target.value.replace(/,/g, ""))) > filterVals.CaratMax
    ) {
      e.preventDefault();
    } else if (Number(num) == 0) {
      if (e.target.value == "") {
        newArr[0] = 0;
        setCaratSlideValue(newArr);
        // var tempArray = filterVals;
        // tempArray["CaratMin"] = 0;
        // setFilterVals(tempArray);
        // dispatch(handleSetSaveEveryTimeRequestTab(tempArray));
      } else {
        if (newArr[0] == 0 && Value.includes(".")) {
          newArr[0] = Value;
          setCaratSlideValue(newArr);
        } else {
          if (newArr[0] == 0) {
            newArr[0] = parseFloat(Value);
            setCaratSlideValue(newArr);
          } else {
            newArr[0] = Value;
            setCaratSlideValue(newArr);
          }
        }
        // var tempArray = filterVals;
        // tempArray["CaratMin"] = parseInt(Value);
        // setFilterVals(tempArray);
        // dispatch(handleSetSaveEveryTimeRequestTab(tempArray));
      }
    } else if (Number(num) == 1) {
      if (e.target.value == "") {
        newArr[1] = 0;
        setCaratSlideValue(newArr);
        // var tempArray = filterVals;
        // tempArray["CaratMax"] = 0;
        // setFilterVals(tempArray);
        // dispatch(handleSetSaveEveryTimeRequestTab(tempArray));
      } else {
        if (newArr[1] == 0 && Value.includes(".")) {
          newArr[1] = Value;
          setCaratSlideValue(newArr);
        } else {
          if (newArr[1] == 0) {
            newArr[1] = parseFloat(Value);
            setCaratSlideValue(newArr);
          } else {
            newArr[1] = Value;
            setCaratSlideValue(newArr);
          }
        }
        // var tempArray = filterVals;
        // tempArray["CaratMax"] = parseInt(Value);
        // setFilterVals(tempArray);
        // dispatch(handleSetSaveEveryTimeRequestTab(tempArray));
      }
    }
  };

  function removeSpecialCharactersAndConvertToNumber(inputString) {
    const cleanedString = inputString.replace(/[^0-9.]/g, "");
    // Remove a trailing hyphen, if present
    const cleanedStringWithoutHyphen = cleanedString.replace(/-$/, "");
    // Convert the cleaned string to a number
    return parseFloat(cleanedStringWithoutHyphen, 10);
  }

  const handleLabGrownFilterChange = (val, name, type) => {
    if (type == "filter") {
      let tempArray = filterVals[name];
      if (tempArray.includes(val)) {
        tempArray = tempArray.filter((item) => item != val);
      } else {
        tempArray.push(val);
      }
      setFilterVals({
        ...filterVals,
        [name]: tempArray,
      });
    } else {
      if (
        name == "DepthMin" ||
        name == "DepthMax" ||
        name == "TableMin" ||
        name == "TableMax"
      ) {
        if (val > 100 || val < 0) {
          return false;
        }
        setFilterVals({
          ...filterVals,
          [name]: removeSpecialCharactersAndConvertToNumber(val),
        });
      } else {
        setFilterVals({
          ...filterVals,
          [name]: val,
        });
      }
    }
    let tempFilters = filterVals;
    if (name == "Certificates") {
      tempFilters[name] = val;
    }
    dispatch(handleSetSaveEveryTimeRequestTab(tempFilters));
  };

  const handleInputDiamondDataChange = (e, name, type) => {
    setDiamondFormData({
      ...diamondFormData,
      [name]: e.target.value,
    });
    if (name == "firstName") {
      setValidations((prevdata) => ({
        ...prevdata,
        firstNameVal: "",
      }));
    }
    if (name == "lastName") {
      setValidations((prevdata) => ({
        ...prevdata,
        lastNameVal: "",
      }));
    }
    if (name == "phoneNumber") {
      setValidations((prevdata) => ({
        ...prevdata,
        phoneNumberVal: "",
      }));
    }
    if (name == "emailAddress") {
      setValidations((prevdata) => ({
        ...prevdata,
        emailAddressVal: "",
      }));
    }
    if (name == "comments") {
      setValidations((prevdata) => ({
        ...prevdata,
        commentsVal: "",
      }));
    }
  };

  const handleInputDiamondDataReset = () => {
    setDiamondFormData(initialDiamondFormInputData);
    setValidations(initialDiamondFormInputDataVal);
  };

  const handleValidation = () => {
    const { firstName, lastName, phoneNumber, emailAddress, comments } =
      diamondFormData;
    const validations = {
      firstNameVal: "",
      lastNameVal: "",
      phoneNumberVal: "",
      emailAddressVal: "",
      commentsVal: "",
    };
    let isValid = true;

    if (!firstName.trim()) {
      isValid = false;
      validations.firstNameVal = "First Name is Compulsory";
    }
    if (!lastName.trim()) {
      isValid = false;
      validations.lastNameVal = "Last Name is Compulsory";
    }

    if (!phoneNumber.trim()) {
      isValid = false;
      validations.phoneNumberVal = "Phone number is compulsory";
    } else if (
      (phoneNumber.length >= 10 && phoneNumber.includes("-")) ||
      (phoneNumber.length >= 10 && phoneNumber.includes(",")) ||
      (phoneNumber.length >= 10 && phoneNumber.includes("+"))
    ) {
      validations.phoneNumberVal = "";
    } else if (!/^(?!0{10,15})(\+\d{1,3}[-]?)?\d{10,15}$/.test(phoneNumber)) {
      isValid = false;
      validations.phoneNumberVal = "Phone number is invalid";
    } else {
      validations.phoneNumberVal = "";
    }

    let validRegex =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (!emailAddress.trim()) {
      isValid = false;
      validations.emailAddressVal = "Email address is compulsory";
    } else if (!emailAddress.match(validRegex)) {
      isValid = false;
      validations.emailAddressVal = "Email address is invalid";
    } else {
      validations.emailAddressVal = "";
    }
    if (!comments.trim()) {
      isValid = false;
      validations.commentsVal = "Comments is Compulsory";
    }

    if (!isValid) {
      setValidations(validations);
    }

    return isValid;
  };

  const handleInputDiamondDataRequest = () => {
    let arrShapes =
      filterVals.Shape.length > 0 ? filterVals.Shape.map((x) => x) : null;
    let arrCutGrades =
      state.Cut.length > 0 ? state.Cut.map((x) => Number(x.value)) : null;
    let arrColors =
      state.Color.length > 0 ? state.Color.map((x) => Number(x.value)) : null;
    let arrPolishs =
      state.Polish.length > 0 ? state.Polish.map((x) => Number(x.value)) : null;
    let arrClaritys =
      state.Clarity.length > 0
        ? state.Clarity.map((x) => Number(x.value))
        : null;
    const isValid = handleValidation();

    if (!isValid) {
      return false;
    }

    try {
      let inputData = {
        dealerID: dealerId.toString(),
        firstName: diamondFormData.firstName,
        lastName: diamondFormData.lastName,
        phoneNumber: diamondFormData.phoneNumber,
        emailAddress: diamondFormData.emailAddress,
        comments: diamondFormData.comments,
        intDealerID: dealerId.toString(),
        subject: "",
        shapeList: arrShapes !== null ? arrShapes.toString() : "",
        priceMin: priceValue[0].toString(),
        priceMax: priceValue[1].toString(),
        caratMin: caratSlideValue[0].toString(),
        caratMax: caratSlideValue[1].toString(),
        colorMin: arrColors !== null ? Math.min(...arrColors).toString() : "",
        colorMax: arrColors !== null ? Math.max(...arrColors).toString() : "",
        clarityMin:
          arrClaritys !== null ? Math.min(...arrClaritys).toString() : "",
        clarityMax:
          arrClaritys !== null ? Math.max(...arrClaritys).toString() : "",
        cutgradeMin:
          arrCutGrades !== null ? Math.min(...arrCutGrades).toString() : "",
        cutgradeMax:
          arrCutGrades !== null ? Math.max(...arrCutGrades).toString() : "",
        certificate:
          filterVals.Certificates && filterVals.Certificates.toString(), //Certificate
        depthMin: filterVals.DepthMin.toString(),
        depthMax: filterVals.DepthMax.toString(),
        tableMin: filterVals.TableMin.toString(),
        tableMax: filterVals.TableMax.toString(),
        polishList:
          filterVals.Polish.length > 0 ? filterVals.Polish.toString() : "",
        symmetryList:
          filterVals.Symmetry.length > 0 ? filterVals.Symmetry.toString() : "",
        fluorescenceList:
          filterVals.Fluorescence.length > 0
            ? filterVals.Fluorescence.toString()
            : "",
        cutGradeList:
          filterVals.Cut.length > 0 ? filterVals.Cut.toString() : "",
        colorList:
          filterVals.Color.length > 0 ? filterVals.Color.toString() : "",
        clarityList:
          filterVals.Clarity.length > 0 ? filterVals.Clarity.toString() : "",
      };
      setReqDiamondLoading(true);
      SelectYourDiamondService.SendMailToRetailerForNoResults(inputData)
        .then((response) => {
          let message = response.data.message;
          if (message == "Success") {
            setReqModalDataForApiRes({
              show: true,
              success: true,
              msg: "You Have Successully Requested For Diamond.",
            });
            setReqDiamondLoading(false);
            setDiamondFormData(initialDiamondFormInputData);
          } else {
            setReqModalDataForApiRes({
              show: true,
              success: false,
              msg: "Cannot Request For Diamond.",
            });
            setReqDiamondLoading(false);
          }
        })
        .catch((error) => {
          console.log(error);
          setReqDiamondLoading(false);
        });
    } catch (error) {
      console.log(error);
      setReqDiamondLoading(false);
    }
  };

  const handleGetDiamondFilters = (id, pageNo, pageSize, val) => {
    try {
      let inputData = {
        dealerID: id.toString(),
      };
      setFilterLoading(true);
      SelectYourDiamondService.GetAvailablePendantbuilderFilters(inputData)
        .then((response) => {
          let message = response.data.message;
          let responseData =
            response.data.responseData.availableFilters_Response;
          let tempAvailableFilterData = state;
          let tempAvailableFilterValData = filterVals;
          if (message == "Success") {
            if (responseData) {
              tempAvailableFilterData.Shape =
                responseData.ringBuilderSettingsShape;
              tempAvailableFilterData.Cut =
                responseData.ringBuildersettingsCuts;
              tempAvailableFilterData.Color =
                responseData.ringBuildersettingsColor;
              tempAvailableFilterData.Clarity =
                responseData.ringBuildersettingsClarity;
              tempAvailableFilterData.Polish =
                responseData.ringBuildersettingsPolish;
              tempAvailableFilterData.Fluorescence =
                responseData.ringBuildersettingsFluorescence;
              tempAvailableFilterData.Symmetry =
                responseData.ringBuildersettingsSymmetry;
              tempAvailableFilterData.Certificates =
                responseData.ringBuildersettingsCertificates;
              tempAvailableFilterData.Clarity =
                responseData.ringBuildersettingsClarity;
              setIsAdvance(responseData.advanced);
              setCurrencySymbol(responseData.currencySign);
              setCaratSlideValue([0, 22]);
              setPriceValue([0, 1000000]);
              setShowAdvanced(responseData.advancedOption);
              setState({
                ...state,
                ...tempAvailableFilterData,
              });
              var saveSearchObj = resetVal?.RequestDiamondFilter;
              if (val) {
                if (saveSearchObj) {
                  setFilterVals(saveSearchObj);
                  setPriceValue([
                    saveSearchObj.PriceMin,
                    saveSearchObj.PriceMax,
                  ]);
                  setCaratSlideValue([
                    saveSearchObj.CaratMin,
                    saveSearchObj.CaratMax,
                  ]);
                }
              } else {
                setFilterVals({
                  ...filterVals,
                  ...tempAvailableFilterValData,
                });
                var tempObj = tempAvailableFilterValData;
                tempAvailableFilterValData.Shape = [];
                tempAvailableFilterValData.Cut = [];
                tempAvailableFilterValData.Color = [];
                tempAvailableFilterValData.Clarity = [];
                tempAvailableFilterValData.Polish = [];
                tempAvailableFilterValData.Fluorescence = [];
                tempAvailableFilterValData.Symmetry = [];
                tempAvailableFilterValData.Intensity = [];
                tempAvailableFilterValData.Certificates = [];
                tempAvailableFilterValData.PriceMin = 0;
                tempAvailableFilterValData.PriceMax = 1000000;
                tempAvailableFilterValData.CaratMin = 0;
                tempAvailableFilterValData.CaratMax = 22;
                tempAvailableFilterValData.TableMin = 0;
                tempAvailableFilterValData.TableMax = 100;
                tempAvailableFilterValData.DepthMin = 0;
                tempAvailableFilterValData.DepthMax = 100;
                setPriceValue([0, 1000000]);
                setCaratSlideValue([0, 22]);
                tempObj["disabled"] = {};
                setFilterVals({
                  ...filterVals,
                  ...tempObj,
                });
                dispatch(handleSetSaveFilterRequestTab(tempObj));
              }
              setFilterLoading(false);
            } else {
            }
          } else {
            setFilterLoading(false);
          }
          setShowAllContent(false);
        })
        .catch((error) => {
          console.log(error);
          setFilterLoading(false);
          setShowAllContent(false);
        });
    } catch (error) {
      console.log(error);
      setFilterLoading(false);
      setShowAllContent(false);
    }
  };
  const handleCloseVideoImageModal = () => {
    setVideoImageModal(false);
  };

  const handleFilterChangeCaratVal = (e) => {
    debugger;
    setCaratSlideValue(e);
  };

  const handleFilterChangePriceVal = (e) => {
    setPriceValue(e);
  };

  useEffect(() => {
    setFilterVals({
      Shape: [],
      Cut: [],
      Color: [],
      Clarity: [],
      Polish: [],
      Fluorescence: [],
      Symmetry: [],
      Certificates: [],
      TableMin: state.TableMin,
      TableMax: state.TableMax,
      DepthMin: state.DepthMin,
      DepthMax: state.DepthMax,
      PriceMin: state.PriceMin,
      PriceMax: state.PriceMax,
      CaratMin: state.CaratMin,
      CaratMax: state.CaratMax,
      disabled: {},
    });
    setPriceValue([state.PriceMin, state.PriceMax]);
    setCaratSlideValue([state.CaratMin, state.CaratMax]);
  }, [resetVal.resetRequest]);

  useLayoutEffect(() => {
    if (paramdealerId) {
      handleGetColorsData(paramdealerId);
    } else {
      if (loginDetails) {
        if (loginDetails.dealerId) {
          handleGetColorsData(loginDetails.dealerId);
        }
      }
    }
  }, [previewcolor.toogle]);

  const handleGetColorsData = async (id) => {
    let inputData = {
      dealerID: Number(id),
      moduleName: "PendantBuilder",
      hoverEffect: "",
      columnHeaderAccent: "",
      linkColor: "",
      actionButton: "",
    };
    try {
      await colorPanelService
        .GetHoverEffect(inputData)
        .then((response) => {
          let msg = response.data.message;
          let initialColorObj =
            response.data.responseData.initialHoverEffectList[0];
          let newColorObj =
            response.data.responseData.ringBuilder_HoverEffect_List[0];
          let colorObj = {
            hoverEffect: "",
            columnHeaderAccent: "",
            linkColor: "",
            actionButton: "",
          };

          if (msg == "Success") {
            if (initialColorObj) {
              let tempobj = colorObj;
              tempobj.hoverEffect = newColorObj
                ? newColorObj.hoverEffect == ""
                  ? initialColorObj.hoverEffect
                  : newColorObj.hoverEffect
                : initialColorObj.hoverEffect;
              tempobj.columnHeaderAccent = newColorObj
                ? newColorObj.columnHeaderAccent == ""
                  ? initialColorObj.columnHeaderAccent
                  : newColorObj.columnHeaderAccent
                : initialColorObj.columnHeaderAccent;
              tempobj.linkColor = newColorObj
                ? newColorObj.linkColor == ""
                  ? initialColorObj.linkColor
                  : newColorObj.linkColor
                : initialColorObj.linkColor;
              tempobj.actionButton = newColorObj
                ? newColorObj.actionButton == ""
                  ? initialColorObj.actionButton
                  : newColorObj.actionButton
                : initialColorObj.actionButton;
              if (tempobj) {
                setVarColorObj({
                  ...varColorObj,
                  callToActionColor: tempobj.actionButton,
                  hoverColor: tempobj.hoverEffect,
                  columnHeaderAccentColor: tempobj.columnHeaderAccent,
                  linkColor: tempobj.linkColor,
                });
                lightOrDark(tempobj.columnHeaderAccent, "header");
                lightOrDark(tempobj.callToActionColor, "button");
                lightOrDark(tempobj.hoverColor, "hover");
              } else {
                setVarColorObj({
                  ...varColorObj,
                  callToActionColor: initialColorObj.actionButton,
                  hoverColor: initialColorObj.hoverEffect,
                  columnHeaderAccentColor: initialColorObj.columnHeaderAccent,
                  linkColor: initialColorObj.linkColor,
                });
                lightOrDark(initialColorObj.columnHeaderAccent, "header");
                lightOrDark(initialColorObj.callToActionColor, "button");
                lightOrDark(initialColorObj.hoverColor, "hover");
              }
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  function lightOrDark(color, type) {
    // Variables for red, green, blue values
    let r, g, b, hsp;

    if (color) {
      // Check the format of the color, HEX or RGB?
      if (color.match(/^rgb/)) {
        // If RGB --> store the red, green, blue values in separate variables
        color = color.match(
          /^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/
        );

        r = color[1];
        g = color[2];
        b = color[3];
      } else {
        // If hex --> Convert it to RGB: http://gist.github.com/983661
        color = +(
          "0x" + color.slice(1).replace(color.length < 5 && /./g, "$&$&")
        );

        r = color >> 16;
        g = (color >> 8) & 255;
        b = color & 255;
      }

      // HSP (Highly Sensitive Poo) equation from http://alienryderflex.com/hsp.html
      hsp = Math.sqrt(0.299 * (r * r) + 0.587 * (g * g) + 0.114 * (b * b));

      // Using the HSP value, determine whether the color is light or dark
      // if hsp> 127.5 then it is dark
      if (hsp > 127.5) {
        if (type == "header") {
          setIsLightHeaderColor(true);
        } else if (type == "button") {
          setIsLightButtonColor(true);
        } else {
          setIsLightHoverColor(true);
        }
      } else {
        if (type == "header") {
          setIsLightHeaderColor(false);
        } else if (type == "button") {
          setIsLightButtonColor(false);
        } else {
          setIsLightHoverColor(false);
        }
      }
    }
  }

  const Wrapper = styled.div`
    & .whole__shapes .shapes__block .image:hover {
      border: 1px solid ${varColorObj.hoverColor};
    }

    & .whole__shapes .shapes__block:hover .image {
      border: 1px solid ${varColorObj.hoverColor};
    }

    & .whole__shapes .shapes__block .image .selected {
      border: 1px solid ${varColorObj.linkColor};
    }

    & .whole__shapes .shapes__block.selected .image {
      border: 1px solid ${varColorObj.linkColor};
    }

    & .img__block .imgselct__div:hover {
      border-bottom: 2px solid ${varColorObj.hoverColor};
    }

    & .img__block .imgselct__div.selected {
      border-bottom: 2px solid ${varColorObj.linkColor};
    }

    & .selection__block .selection__box:hover {
      border: 1px solid ${varColorObj.hoverColor};
      background-color: ${varColorObj.hoverColor};
    }
    & .selection__block .selection__box.selected {
      border: 1px solid ${varColorObj.linkColor};
      background-color: ${varColorObj.linkColor};
    }

    & .ant-table-thead > tr > th {
      background: ${varColorObj.columnHeaderAccentColor} !important;
      font-family: "LexendDeca-Semibold";
      height: 50px;
      color: ${isLightHeaderColor ? "#3b4452" : "#ffffff"} !important;
      line-height: 14px;
    }

    & .ant-btn-primary {
      background: ${varColorObj.callToActionColor} !important;
      border-color: ${varColorObj.callToActionColor} !important;
    }
    & .ant-btn-primary:hover {
      background: ${varColorObj.hoverColor} !important;
      border-color: ${varColorObj.hoverColor} !important;
    }

    & .primary-btn {
      background: ${varColorObj.callToActionColor} !important;
      border-radius: 5px !important;
      color: ${isLightButtonColor ? "#3b4452" : "#ffffff"} !important;
      border: none;
      padding: 0px 10px;
      height: 35px;
    }

    & .primary-btn:hover {
      background: ${varColorObj.hoverColor} !important;
      border-radius: 5px !important;
      color: ${isLightHoverColor ? "#3b4452" : "#ffffff"} !important;
      border: none;
      padding: 0px 10px;
      height: 35px;
    }

    & .ant-btn {
      background: ${varColorObj.callToActionColor} !important;
      border-radius: 5px !important;
      color: ${isLightButtonColor ? "#3b4452" : "#fff"} !important;
      border: none;
      padding: 0px 10px;
      height: 35px;
    }

    & .ant-btn:hover {
      background: ${varColorObj.hoverColor} !important;
      border-radius: 5px !important;
      color: ${isLightHoverColor ? "#3b4452" : "#ffffff"} !important;
      border: none;
      padding: 0px 10px;
      height: 35px;
    }
    & .ant-input-search-button {
      background: #fff !important;
      border: 1px solid #d9d9d9;
      border-radius: 0 !important;
      height: 32px;
      border-left: 0;
    }

    & .ant-input-search-button:hover {
      background: #fff !important;
      border: 1px solid #d9d9d9;
      border-radius: 0 !important;
      height: 32px;
      border-left: 0;
    }
    & .linkText {
      color: ${varColorObj.linkColor};
      text-decoration: none;
      cursor: pointer;
    }

    & .linkText:hover {
      color: ${varColorObj.hoverColor};
    }

    & .iframe__block .itemdetails__btns p:hover img {
      filter: invert(64%) sepia(29%) saturate(4028%) hue-rotate(164deg)
        brightness(93%) contrast(89%);
      color: ${varColorObj.hoverColor};
    }

    & .iframe__block .itemdetails__btns p:hover span {
      color: ${varColorObj.hoverColor};
    }

    & .ant-pagination-item-active {
      border: 1px solid ${varColorObj.linkColor} !important;
    }

    & .ant-pagination-item:hover {
      border: 1px solid ${varColorObj.hoverColor};
    }
    & .nav-tabs .nav-link.active {
      background: ${varColorObj.columnHeaderAccentColor} !important;
      color: ${isLightHeaderColor ? "#3b4452" : "#ffffff"} !important;
    }

    & .list_icon.active {
      border: 1px solid ${varColorObj.callToActionColor};
      background: ${varColorObj.callToActionColor} !important;
      color: ${isLightButtonColor ? "#3b4452" : "#ffffff"} !important;
    }

    & .grid_icon.active {
      border: 1px solid ${varColorObj.callToActionColor};
      background: ${varColorObj.callToActionColor} !important;
      color: ${isLightButtonColor ? "#3b4452" : "#ffffff"} !important;
    }

    & .grid_icon:hover {
      border: 1px solid ${varColorObj.hoverColor};
      background: ${varColorObj.hoverColor} !important;
      color: ${isLightHoverColor ? "#3b4452" : "#ffffff"} !important;
    }

    & .list_icon:hover {
      border: 1px solid ${varColorObj.hoverColor};
      background: ${varColorObj.hoverColor} !important;
      color: ${isLightHoverColor ? "#3b4452" : "#ffffff"} !important;
    }

    & .ant-input-search-button {
      color: rgba(0, 0, 0, 0.45) !important;
    }
    & .ant-input-search-button:hover {
      color: rgba(0, 0, 0, 0.45) !important;
    }
  `;

  /* Color Individually Ends */

  return (
    <Spin spinning={showAllContent} style={{ minHeight: "50vh" }}>
      {showAllContent == false && (
        <React.Fragment>
          <div className="allprod_section jwlsrch__section app__preview choose__setting">
            <div className="tabs__content">
              <div className="diamond__details">
                <div className="row border__block mb-0">
                  <div className="col-md-9">
                    <div className="row">
                      <Spin spinning={filterloading}>
                        <div className="form__fields filter_inpsection border-0 p-0 mt-3 mh-auto">
                          <div className="col-md-12">
                            <div className="row">
                              <Wrapper>
                                <div>
                                  <div className="col-lg-3 col-md-6">
                                    <div className="input__block mb-1">
                                      <div className="input__container">
                                      <label>Shape</label>
                                      <Tooltip
                                        placement="rightTop"
                                        title={`A diamond’s shape is not the same as a diamond’s cut. The shape refers to the general outline of the stone, and not its light refractive qualities. Look for a shape that best suits the ring setting you have chosen, as well as the recipient’s preference and personality.`}
                                      >
                                        <img src={UnionIcon} alt="" className="input__icon"  />
                                      </Tooltip>
                                    </div>
                                    </div>
                                  </div>
                                  <div className="whole__shapes col-lg-12">
                                    {state.Shape &&
                                      state.Shape.map((item, i) => {
                                        return (
                                          <Fragment>
                                            <div
                                              className={
                                                filterVals.Shape &&
                                                filterVals.Shape.includes(
                                                  item.value
                                                )
                                                  ? "shapes__block selected"
                                                  : "shapes__block"
                                              }
                                              onClick={() =>
                                                handleLabGrownFilterChange(
                                                  item.value,
                                                  "Shape",
                                                  "filter"
                                                )
                                              }
                                            >
                                              <div className="image">
                                                <img src={item.shapeImage} />
                                              </div>
                                              <p>{item.name}</p>
                                            </div>
                                          </Fragment>
                                        );
                                      })}
                                  </div>

                                  <div className="col-md-12">
                                    <div className="input__block mb-1">
                                      <div className="input__container">
                                        <label>Cut</label>
                                        <Tooltip
                                          placement="rightTop"
                                          title={`Not to be confused with shape, a diamond’ s cut rating tells you how well its proportions interact with light.By evaluating the angles and proportions of the diamond, the cut grade is designed to tell you how sparkly and brilliant your stone is.Cut grading is usually not available for fancy shapes(any shape that is not round), because the mathematical formula that determines light return becomes less reliable when different length to width ratios are factored in .`}
                                        >
                                          <img src={UnionIcon} alt=""  className="input__icon"  />
                                        </Tooltip>
                                      </div>
                                    </div>
                                    <div className="selection__block row">
                                      {state.Cut &&
                                        state.Cut.map((item, i) => {
                                          return (
                                            <Fragment>
                                              <div className="col">
                                                <div
                                                  className={
                                                    filterVals.Cut &&
                                                    filterVals.Cut.includes(
                                                      item.value
                                                    )
                                                      ? "selection__box selected"
                                                      : "selection__box"
                                                  }
                                                  onClick={() =>
                                                    handleLabGrownFilterChange(
                                                      item.value,
                                                      "Cut",
                                                      "filter"
                                                    )
                                                  }
                                                ></div>
                                                <p>{item.name}</p>
                                              </div>
                                            </Fragment>
                                          );
                                        })}
                                    </div>
                                  </div>
                                  <div className="col-md-12">
                                    <div className="input__block mb-1">
                                      <div className="input__container">
                                        <label>Color</label>
                                        <Tooltip
                                          placement="rightTop"
                                          title={`The color scale measures the degree of colorlessness in a diamond. D is the highest and most colorless grade, but also the most expensive. To get the most value for your budget, look for an eye colorless stone.`}
                                        >
                                          <img src={UnionIcon} alt="" className="input__icon"  />
                                        </Tooltip>
                                      </div>
                                    </div>
                                    <div className="selection__block row">
                                      {state.Color &&
                                        state.Color.map((item, i) => {
                                          return (
                                            <Fragment>
                                              <div className="col">
                                                <div
                                                  className={
                                                    filterVals.Color &&
                                                    filterVals.Color.includes(
                                                      item.value
                                                    )
                                                      ? "selection__box selected"
                                                      : "selection__box"
                                                  }
                                                  onClick={() =>
                                                    handleLabGrownFilterChange(
                                                      item.value,
                                                      "Color",
                                                      "filter"
                                                    )
                                                  }
                                                ></div>
                                                <p>{item.name}</p>
                                              </div>
                                            </Fragment>
                                          );
                                        })}
                                    </div>
                                  </div>
                                  <div className="col-md-12">
                                    <div className="input__block mb-1">
                                      <div className="input__container">
                                        <label>Clarity</label>
                                        <Tooltip
                                          placement="rightTop"
                                          title={`A diamond’s clarity refers to the tiny traces of natural elements that are trapped inside the stone. 99% of diamonds contain inclusions or flaws. You do not need a flawless diamond - they are very rare and expensive - but you want to look for one that is perfect to the naked eye. Depending on the shape of the diamond, the sweet spot for clarity is usually between VVS2 to SI1.`}
                                        >
                                          <img src={UnionIcon} alt="" className="input__icon"  />
                                        </Tooltip>
                                      </div>
                                    </div>
                                    <div className="selection__block row">
                                      {state.Clarity &&
                                        state.Clarity.map((item, i) => {
                                          return (
                                            <Fragment>
                                              <div className="col">
                                                <div
                                                  className={
                                                    filterVals.Clarity &&
                                                    filterVals.Clarity.includes(
                                                      item.value
                                                    )
                                                      ? "selection__box selected"
                                                      : "selection__box"
                                                  }
                                                  onClick={() =>
                                                    handleLabGrownFilterChange(
                                                      item.value,
                                                      "Clarity",
                                                      "filter"
                                                    )
                                                  }
                                                ></div>
                                                <p>{item.name}</p>
                                              </div>
                                            </Fragment>
                                          );
                                        })}
                                    </div>
                                  </div>
                                </div>
                              </Wrapper>
                              <div className="col-md-6">
                                <div className="sliderrange__maindiv d-block">
                                  <div className="input__container">
                                      <label> {"Carats"} </label>
                                      <Tooltip
                                        placement="rightTop"
                                        title={`Carat is a unit of measurement to determine a diamond’s weight. Typically, a higher carat weight means a larger looking diamond, but that is not always the case. Look for the mm measurements of the diamond to determine its visible size.`}
                                      >
                                          <img src={UnionIcon} alt="" style={{ cursor: 'pointer' , marginTop:'1px',width:'14px'}}/>
                                      </Tooltip>
                                    </div>
                                  <div className="col-md-12 mt-1">
                                    <Slider
                                      range
                                      value={caratSlideValue}
                                      min={filterVals.CaratMin}
                                      max={filterVals.CaratMax}
                                      step={0.01}
                                      onChange={(e) => {
                                        handleFilterChangeCaratVal(e);
                                      }}
                                    />
                                    <div className="rangeSlider__values">
                                      <div className="input__block">
                                        <input
                                          type="text"
                                          value={caratSlideValue[0].toLocaleString(
                                            "en-US"
                                          )}
                                          onChange={(e) => {
                                            firstsetCaratSlideValue(e, 0);
                                          }}
                                          onKeyPress={onKeyPressEvent1}
                                        />
                                      </div>

                                      <div className="input__block">
                                        <input
                                          type="text"
                                          value={caratSlideValue[1].toLocaleString(
                                            "en-US"
                                          )}
                                          onChange={(e) => {
                                            firstsetCaratSlideValue(e, 1);
                                          }}
                                          onKeyPress={onKeyPressEvent1}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="sliderrange__maindiv d-block">
                                  <div className="input__container">
                                      <label>Price Range</label>
                                      <Tooltip
                                        placement="rightTop"
                                        title={`This refers to different types of Price to filter and select the appropriate ring as per your requirements. Look for the best suit price of your chosen ring.`}
                                      >
                                        <img src={UnionIcon} alt=""  style={{ cursor: 'pointer' , marginTop:'1px',width:'14px'}}  />
                                      </Tooltip>
                                    </div>
                                  <div className="col-md-12 mt-1">
                                    <Slider
                                      range
                                      value={priceValue}
                                      min={state.PriceMin}
                                      max={state.PriceMax}
                                      onChange={(e) => {
                                        handleFilterChangePriceVal(e);
                                      }}
                                    />
                                    <div className="rangeSlider__values">
                                      <div className="input__block">
                                        <input
                                          type="text"
                                          value={
                                            currencySymbol +
                                            priceValue[0]
                                              .toString()
                                              .replace(
                                                /\B(?=(\d{3})+(?!\d))/g,
                                                ","
                                              )
                                          }
                                          onChange={(e) => {
                                            firstSliderPriceValue(e, 0);
                                          }}
                                        />
                                      </div>

                                      <div className="input__block">
                                        <input
                                          type="text"
                                          value={
                                            currencySymbol +
                                            priceValue[1]
                                              .toString()
                                              .replace(
                                                /\B(?=(\d{3})+(?!\d))/g,
                                                ","
                                              )
                                          }
                                          onChange={(e) => {
                                            firstSliderPriceValue(e, 1);
                                          }}
                                          onKeyPress={onKeyPressEvent1}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div>
                                <Wrapper>
                                  {isAdvance ? (
                                    <div className="col-md-12 mt-2">
                                      <div className="input__block d-flex align-item-center">
                                        <button
                                          className="primary-btn mr-1"
                                          style={{
                                            width: "25px",
                                            height: "25px",
                                            padding: "0px",
                                          }}
                                          onClick={() =>
                                            setShowAdvanced(!showAdvanced)
                                          }
                                        >
                                          {showAdvanced ? "-" : "+"}
                                        </button>
                                        <label>Advanced</label>
                                      </div>
                                    </div>
                                  ) : null}
                                </Wrapper>

                                {showAdvanced == true ? (
                                  <div>
                                    <Wrapper>
                                      <div className="col-md-12">
                                        <div className="input__block mb-1">
                                          <div className="input__container">
                                            <label>Polish</label>
                                            <Tooltip
                                              placement="rightTop"
                                              title={`Polish describes how smooth the surface of a diamond is. Aim for an Excellent or Very Good polish rating.`}
                                            >
                                              <img src={UnionIcon} alt=""  className="input__icon" />
                                            </Tooltip>
                                          </div>
                                        </div>
                                        <div className="selection__block row">
                                          {state.Polish &&
                                            state.Polish.map((item, i) => {
                                              return (
                                                <Fragment>
                                                  <div className="col">
                                                    <div
                                                      className={
                                                        filterVals.Polish &&
                                                        filterVals.Polish.includes(
                                                          item.value
                                                        )
                                                          ? "selection__box selected"
                                                          : "selection__box"
                                                      }
                                                      onClick={() =>
                                                        handleLabGrownFilterChange(
                                                          item.value,
                                                          "Polish",
                                                          "filter"
                                                        )
                                                      }
                                                    ></div>
                                                    <p>{item.name}</p>
                                                  </div>
                                                </Fragment>
                                              );
                                            })}
                                        </div>
                                      </div>

                                      <div className="col-md-12">
                                        <div className="input__block mb-1">
                                          <div className="input__container">
                                            <label>Fluorescence</label>
                                            <Tooltip
                                              placement="rightTop"
                                              title={`Fluorescence tells you how a diamond responds to ultraviolet light - does it glow under a black light? Diamonds with no fluorescence are generally priced higher on the market, but it is rare for fluorescence to have any visual impact on the diamond; some fluorescence can even enhance the look of the stone. Our site recommends searching for diamonds with none to medium fluorescence, and keeping open the option of strong fluorescence for additional value.`}
                                            >
                                              <img src={UnionIcon} alt=""  className="input__icon"  />
                                            </Tooltip>
                                          </div>
                                        </div>
                                        <div className="selection__block row">
                                          {state.Fluorescence &&
                                            state.Fluorescence.map(
                                              (item, i) => {
                                                return (
                                                  <Fragment>
                                                    <div className="col">
                                                      <div
                                                        className={
                                                          filterVals.Fluorescence &&
                                                          filterVals.Fluorescence.includes(
                                                            item.value
                                                          )
                                                            ? "selection__box selected"
                                                            : "selection__box"
                                                        }
                                                        onClick={() =>
                                                          handleLabGrownFilterChange(
                                                            item.value,
                                                            "Fluorescence",
                                                            "filter"
                                                          )
                                                        }
                                                      ></div>
                                                      <p>{item.name}</p>
                                                    </div>
                                                  </Fragment>
                                                );
                                              }
                                            )}
                                        </div>
                                      </div>

                                      <div className="col-md-12">
                                        <div className="input__block mb-1">
                                          <div className="input__container">
                                            <label>Symmetry</label>
                                            <Tooltip
                                              placement="rightTop"
                                              title={`Symmetry describes how symmetrical the diamond is cut all the way around, which is a contributing factor to a diamond’s sparkle and brilliance. Aim for an Excellent or Very Good symmetry rating for round brilliant shapes, and Excellent to Good for fancy shapes.`}
                                            >
                                              <img src={UnionIcon} alt="" className="input__icon"  />
                                            </Tooltip>
                                          </div>
                                        </div>
                                        <div className="selection__block row">
                                          {state.Symmetry &&
                                            state.Symmetry.map((item, i) => {
                                              return (
                                                <Fragment>
                                                  <div className="col">
                                                    <div
                                                      className={
                                                        filterVals.Symmetry &&
                                                        filterVals.Symmetry.includes(
                                                          item.value
                                                        )
                                                          ? "selection__box selected"
                                                          : "selection__box"
                                                      }
                                                      onClick={() =>
                                                        handleLabGrownFilterChange(
                                                          item.value,
                                                          "Symmetry",
                                                          "filter"
                                                        )
                                                      }
                                                    ></div>
                                                    <p>{item.name}</p>
                                                  </div>
                                                </Fragment>
                                              );
                                            })}
                                        </div>
                                      </div>
                                    </Wrapper>
                                    <div className="row">
                                      <div className="col-md-4">
                                        <div className="input__block mb-1">
                                          <div className="input__container">
                                              <label>Depth</label>
                                              <Tooltip
                                                placement="rightTop"
                                                title={`Depth percentage is the height of the diamond measured from the culet to the table, divided by the width of the diamond. The lower the depth %, the larger the diamond will appear (given the same weight), but if this number is too low then the brilliance of the diamond will be sacrificed. The depth percentage is one of the elements that determines the Cut grading.`}
                                              >
                                                <img src={UnionIcon} alt=""  className="input__icon" />
                                              </Tooltip>
                                          </div>
                                          <div className="row">
                                            <div className="col-md-6 mobile__bottomspace">
                                              <input
                                                type="number"
                                                value={filterVals.DepthMin}
                                                onChange={(e) =>
                                                  handleLabGrownFilterChange(
                                                    e.target.value,
                                                    "DepthMin",
                                                    "input"
                                                  )
                                                }
                                              />
                                            </div>
                                            <div className="col-md-6">
                                              <input
                                                type="number"
                                                value={filterVals.DepthMax}
                                                onChange={(e) =>
                                                  handleLabGrownFilterChange(
                                                    e.target.value,
                                                    "DepthMax",
                                                    "input"
                                                  )
                                                }
                                              />
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="col-md-4">
                                        <div className="input__block mb-1">
                                          <div className="input__container">
                                              <label>Table</label>
                                              <Tooltip
                                                placement="rightTop"
                                                title={`Table percentage is the width of a diamond’s largest facet the table divided by its overall width. It tells you how big the “face” of a diamond is.`}
                                              >
                                                <img src={UnionIcon} alt="" className="input__icon" />
                                              </Tooltip>
                                          </div>
                                          <div className="row">
                                            <div className="col-md-6 mobile__bottomspace">
                                              <input
                                                type="number"
                                                value={filterVals.TableMin}
                                                onChange={(e) =>
                                                  handleLabGrownFilterChange(
                                                    e.target.value,
                                                    "TableMin",
                                                    "input"
                                                  )
                                                }
                                              />
                                            </div>
                                            <div className="col-md-6">
                                              <input
                                                type="number"
                                                value={filterVals.TableMax}
                                                onChange={(e) =>
                                                  handleLabGrownFilterChange(
                                                    e.target.value,
                                                    "TableMax",
                                                    "input"
                                                  )
                                                }
                                              />
                                            </div>
                                          </div>
                                        </div>
                                      </div>

                                      <div className="col-md-4">
                                        <div className="input__block mb-1">
                                          <label>Certificates</label>
                                          <Select
                                            className="border__grey"
                                            showSearch
                                            placeholder="Certificates"
                                            optionFilterProp="children"
                                            onChange={(e) =>
                                              handleLabGrownFilterChange(
                                                e,
                                                "Certificates",
                                                "input"
                                              )
                                            }
                                            filterOption={(input, option) =>
                                              option.children
                                                .toLowerCase()
                                                .indexOf(input.toLowerCase()) >=
                                              0
                                            }
                                            mode="multiple"
                                            showArrow
                                            value={filterVals.Certificates}
                                          >
                                            {state.Certificates.map((cert) => {
                                              return (
                                                <Option value={cert.value}>
                                                  {cert.name}
                                                </Option>
                                              );
                                            })}
                                          </Select>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                ) : null}
                              </div>
                            </div>
                          </div>
                        </div>
                      </Spin>
                    </div>
                  </div>

                  <div className="col-md-3 diamondimage__block">
                    <div className="">
                      <p>
                        Please enter the details of the type of diamond you are
                        looking for and your contact information.
                      </p>
                      <p>
                        Your information will be submitted to one of our diamond
                        specialists that will contact you.
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-md-12">
                <div class="table__block filter_section pt-0 mt-3">
                  <h5 className="subheading">
                    {" "}
                    No Diamonds Listed In That Range{" "}
                  </h5>
                  <p>
                    {" "}
                    Please enter your contact information. One of our diamond
                    specialists will contact you and assist you with your needs.{" "}
                  </p>
                  <div class="row p-0 border-0 form__fields mh-auto">
                    <div class="col-md-6">
                      <div class="input__block">
                        <label> First Name </label>
                        <input
                          type="text"
                          placeholder="Enter First Name"
                          value={diamondFormData.firstName}
                          className={validations.firstNameVal && "border__red"}
                          onChange={(e) =>
                            handleInputDiamondDataChange(
                              e,
                              "firstName",
                              "input"
                            )
                          }
                        />
                        <div>
                          {validations.firstNameVal && (
                            <p className="error-color-red">
                              {validations.firstNameVal}
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="input__block">
                        <label> Last Name </label>
                        <input
                          type="text"
                          placeholder="Enter Last Name"
                          value={diamondFormData.lastName}
                          className={validations.lastNameVal && "border__red"}
                          onChange={(e) =>
                            handleInputDiamondDataChange(e, "lastName", "input")
                          }
                        />
                        <div>
                          {validations.lastNameVal && (
                            <p className="error-color-red">
                              {validations.lastNameVal}
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="input__block">
                        <label> Phone Number </label>
                        <input
                          type="text"
                          placeholder="Enter Phone Number"
                          value={diamondFormData.phoneNumber}
                          className={
                            validations.phoneNumberVal && "border__red"
                          }
                          onChange={(e) =>
                            handleInputDiamondDataChange(
                              e,
                              "phoneNumber",
                              "input"
                            )
                          }
                        />
                        <div>
                          {validations.phoneNumberVal && (
                            <p className="error-color-red">
                              {validations.phoneNumberVal}
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="input__block">
                        <label> Email Address </label>
                        <input
                          type="text"
                          placeholder="Enter Email Address"
                          value={diamondFormData.emailAddress}
                          className={
                            validations.emailAddressVal && "border__red"
                          }
                          onChange={(e) =>
                            handleInputDiamondDataChange(
                              e,
                              "emailAddress",
                              "input"
                            )
                          }
                        />
                        <div>
                          {validations.emailAddressVal && (
                            <p className="error-color-red">
                              {validations.emailAddressVal}
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                    <div class="col-md-12">
                      <div class="input__block">
                        <label> Comments </label>
                        <textarea
                          value={diamondFormData.comments}
                          className={validations.commentsVal && "border__red"}
                          onChange={(e) =>
                            handleInputDiamondDataChange(e, "comments", "input")
                          }
                        ></textarea>
                        <div>
                          {validations.commentsVal && (
                            <p className="error-color-red">
                              {validations.commentsVal}
                            </p>
                          )}
                        </div>
                      </div>
                    </div>

                    <div class="col-md-12">
                      <div class="save__form">
                        <Button
                          type="primary"
                          className="mr-1"
                          onClick={handleInputDiamondDataReset}
                          style={{
                            background: `${varColorObj.callToActionColor}`,
                            color: "#ffffff",
                          }}
                        >
                          {" "}
                          Reset{" "}
                        </Button>
                        <Button
                          loading={reqDiamondloading}
                          class="primary-btn"
                          type="primary"
                          onClick={handleInputDiamondDataRequest}
                          style={{
                            background: `${varColorObj.callToActionColor}`,
                            color: "#ffffff",
                          }}
                        >
                          {" "}
                          Request{" "}
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <Modal
            className="modalconsupld__section"
            style={{ top: 20 }}
            title={"Video"}
            width={500}
            visible={videoImageModal}
            onCancel={() => handleCloseVideoImageModal()}
            footer={[
              <Button onClick={() => handleCloseVideoImageModal()}>
                {"Cancel"}
              </Button>,
            ]}
          >
            <div className="col-lg-12">
              <div className="modal__body">
                <div className="col-lg-12">
                  <div className="form__fields border-0 p-0">
                    <div className="row">
                      {isVideo ? (
                        selectedRowDetails.videoFileName ? (
                          <iframe
                            width="100%"
                            height="450"
                            title="diamond-video"
                            className="VideoIframe"
                            src={selectedRowDetails.videoFileName}
                          ></iframe>
                        ) : (
                          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                        )
                      ) : selectedRowDetails.imageFileName ? (
                        <img
                          src={selectedRowDetails.imageFileName}
                          alt="img-diamond"
                        />
                      ) : (
                        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Modal>
          <Modal
            title={reqModalDataForApiRes?.success ? "Success" : "Warning"}
            className="datamapping__modal"
            width={400}
            centered
            visible={reqModalDataForApiRes.show}
            // onCancel={() => setReqModalDataForApiRes(initialReqModalDataForApiRes)}
            maskClosable={false}
            okButtonProps={{ style: { display: "none" } }}
            footer={[
              <Button
                key="ok"
                onClick={() =>
                  setReqModalDataForApiRes(initialReqModalDataForApiRes)
                }
              >
                OK
              </Button>,
            ]}
          >
            <div className="form__fields row border-0 p-0">
              <div className="col-lg-12 text-center">
                <p> {reqModalDataForApiRes?.msg ?? ""} </p>
              </div>
            </div>
          </Modal>
        </React.Fragment>
      )}
    </Spin>
  );
};

export default Request;
